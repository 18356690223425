<ng-container *ngIf="dataLoaded">
    <ng-container *ngIf="mode === 1">
        <app-edit-category></app-edit-category>
    </ng-container>
    
    <ng-container *ngIf="mode === 2">
        <app-edit-shippings></app-edit-shippings>
    </ng-container>
    
    <ng-container *ngIf="mode === 3">
        <app-edit-payments></app-edit-payments>
    </ng-container>

    <ng-container *ngIf="mode === 4">
        <app-edit-communication></app-edit-communication>
    </ng-container>

    <ng-container *ngIf="mode === 5">
        <app-edit-terms></app-edit-terms>
    </ng-container>
</ng-container>
