<h1 mat-dialog-title>
  <div class="header">
      <span style="font-size: larger; margin-right: 20px;"> {{getTitle()}} </span>
      <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
  </div>
</h1>
<div fxLayout="column">
  <div fxLayout="column" fxLayoutGap="10px">
    <form [formGroup]="form" style="display: contents">
      <mat-form-field>
        <mat-label>Ονομασία</mat-label>
          <input formControlName="description" [maxlength]="45" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Τιμή</mat-label>
        <input matInput formControlName="price" type="number">
      </mat-form-field>
      <mat-slide-toggle color="primary" formControlName="is_active">{{form.value.is_active ? 'Ενεργή' : 'Ανενεργή'}}</mat-slide-toggle>
      <mat-slide-toggle color="primary" formControlName='has_scale'>{{form.value.has_scale ? "Με κλιμάκωση" :"Χωρίς κλιμάκωση"}}</mat-slide-toggle>
      <button type="button" mat-raised-button [disabled]="form.invalid" (click)="addScale()" (click)="increment()" style="width: 40%" *ngIf="form.value.has_scale">Προσθήκη Κλίμακας</button>
      <div *ngIf="form.value.has_scale" formArrayName="scales">
        <div *ngFor="let scale of scaleFields.controls; let i=index" [formGroupName]="i">
          <mat-form-field [style.width.px]=80 (change)="scaleCheck(i)" style="margin-right: 50px"><mat-label>Από</mat-label>
            <input matInput type="number" formControlName="from"><mat-error *ngIf="!validScale">Παρακαλώ εισάγετε μια έγκυρη τιμή</mat-error>
          </mat-form-field>
          <mat-form-field [style.width.px]=80 (change)="scaleCheck(i)" style="margin-right: 50px"><mat-label>Έως</mat-label>
            <input matInput type="number" formControlName="to"><mat-error *ngIf="!validScale">Παρακαλώ εισάγετε μια έγκυρη τιμή</mat-error>
          </mat-form-field>
          <mat-form-field [style.width.px]=80 style="margin-right: 50px"><mat-label>Τιμή</mat-label>
            <input matInput type="number" formControlName="price">
          </mat-form-field>
          <button *ngIf="i===maxIndex" mat-raised-button (click)="deleteScale(i)">Αφαίρεση</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button (click)="editShipping()" [disabled]="!form.valid" mat-raised-button class="custom-btn">Αποθήκευση αλλαγών</button>
</div>
