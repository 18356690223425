import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  mode: any;
  user: any;
  dataLoaded: boolean;


  constructor(private adminService: AdminService) {
    this.dataLoaded = false;

  }

  async ngOnInit() {
    this.user = await this.adminService.getMyInfo();
    this.dataLoaded = true;
  }

  showDetails(m) {
    this.mode = m;
  }

  changeTab(event) {
    this.mode = event.index + 1;
  }

}
