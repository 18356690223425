

<div fxLayout="row" style="height: 100%" fxLayoutAlign="space-between">
  <div [ngStyle.lt-md]="{'width':'100%'}" fxLayout="column" fxLayoutAlign="start center center" style="width: 80%; height: 100%">
    <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 5%">
      <img src="../../../assets/pr-logo.png">
    </div>
      <div fxLayout="row" fxLayoutAlign="center"  style="margin-top: 15%">
        <h1>Eφαρμογή εκτύπωσης</h1>
      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <h2>Καλωσορίσατε στην εφαρμογή διαχειριστή</h2>
      </div>
      <h3>Εισέλθετε στην εφαρμογή για να:</h3>

      <div>
        <div class="info-div">
          <mat-icon class="info-icon" style="padding-left: 10px; padding-right: 5px;">check_circle</mat-icon>
          <span style="font-size:medium">Να δείτε και να διαχειριστείτε τις εκτυπώσεις</span>
        </div>
        <div class="info-div">
          <mat-icon class="info-icon" style="padding-left: 10px; padding-right: 5px;">check_circle</mat-icon>
          <span style="font-size:medium">Να διαχειριστείτε το λογαριασμό σας</span>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 5%">
        <mat-icon class="icon-color">account_circle</mat-icon>
      </div>

    <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 2%">
      Σύνδεση διαχειριστή
    </div>
    <div fxLayout="row" style="margin-top: 5%; width: 80%;">
      <div fxLayout="column" fxFlex="10">
        <div class="icon-color" style="margin-top: 20px"><mat-icon>account_circle</mat-icon></div>
        <div class="icon-color" style="margin-top: 20px"><mat-icon>vpn_key</mat-icon></div>
      </div>
      <div fxLayout="column" fxFlex="90">
        <mat-form-field>
          <input matInput [(ngModel)]="username" placeholder="Όνομα χρήστη ή Email" required/>
        </mat-form-field>
        <mat-form-field>
          <input matInput [type]="hide ? 'password' : 'text'" placeholder="Συνθηματικό" [(ngModel)]="password" required/>
          <mat-icon style="cursor: pointer;" (click)="hide = !hide"
                    matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" style="margin-top: 10%;" fxLayoutAlign="center">
      <button mat-raised-button [disabled]="!inputsValid()" class="custom-btn" (click)="adminLogin()">Σύνδεση</button>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 5%;">
      <a style="cursor: pointer; border-bottom: 1px solid;" (click)="forgotPassword()">Ανάκτηση κωδικού πρόσβασης</a>
    </div>

  </div>
  <div fxHide.lt-md fxLayout="column" fxLayoutAlign="center center center" style="width: 100%; height: 100%">
    <img style="width: 60vw; height: 100%" src="../../../assets/img/bg2.jpg">
  </div>
</div>

