<div fxLayout="column" class="content" *ngIf="dataLoaded">
  <div *ngIf="+mode !== 3" ngStyle.lt-sm=" ">
    <div fxLayout="row" fxLayoutAlign="center" class="mat-title">
      Πληροφορίες
    </div>
    <mat-divider style="margin: 2%"></mat-divider>

    <div class="center">
      <div fxLayoutAlign="start">
        <mat-icon class="icon-config info-color" style="margin-right: 5px;">info</mat-icon>
        <span style="margin-top: 3px;">
          Εδώ μπορείτε να δείτε και να επεξεργαστείτε τις λεπτομέρειες χρήστη σας. 
        </span>
      </div>
    </div>

    <div class="profile-info-container">
      <div fxHide.lt-sm fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutAlign.xs="start" fxLayoutAlign.md="start"
          fxLayoutAlign.gt-xs="space-evenly center" style="margin-top: 2%; font-size: medium">
          
        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
            <div style="width: 100px;">Όνομα:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.first_name" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.first_name = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px">Επώνυμο:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.last_name" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.last_name = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px;">Email:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.email" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.email = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
            <div style="width: 100px;">Τηλέφωνο:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.phone_number" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.phone_number = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px">Διεύθυνση:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.address" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.address = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
            <div style="width: 100px">ΤΚ:</div>
            <mat-form-field fxFlex>
              <label>
                <input matInput [(ngModel)]="user.postal_code" [readonly]="!edit">
              </label>
              <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.postal_code = ''"
              matSuffix>clear</mat-icon>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div fxHide.gt-xs>
        <div style="width: 90%; margin-left: 30px;">
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
              <div style="width: 100px;">Όνομα:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.first_name" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.first_name = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px">Επώνυμο:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.last_name" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.last_name = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px;">Email:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.email" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.email = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
          </div>
  
          <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
              <div style="width: 100px;">Τηλέφωνο:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.phone_number" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.phone_number = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px">Διεύθυνση:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.address" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.address = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
              <div style="width: 100px">ΤΚ:</div>
              <mat-form-field fxFlex>
                <label>
                  <input matInput [(ngModel)]="user.postal_code" [readonly]="!edit">
                </label>
                <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="user.postal_code = ''"
                matSuffix>clear</mat-icon>
              </mat-form-field>
            </div>
          </div>
  
        </div>
      </div>
      
      <div style="margin-top:1%;" fxLayoutGap="40px">
        <button *ngIf="!edit" style="width: 220px" class="action-btn custom-btn" mat-raised-button (click)="editing()">Επεξεργασία</button>
        <button *ngIf="edit" style="width: 220px" class="action-btn custom-btn" mat-raised-button [disabled]="!isUserValid()" (click)="editInfo()">Αποθήκευση</button>
        <button *ngIf="edit" mat-icon-button style="margin: 10px" (click)="cancelEdit()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
      
      <div style="margin-top: 1.5%;">
        <div fxLayoutAlign="center center center">
          <button style="width: 220px;" mat-raised-button (click)="changePasswordDialog()" class="action-btn custom-btn">Αλλαγή κωδικού πρόσβασης</button>
        </div>
        <!-- <div fxLayoutAlign="center center center" class="mat-title">
          Αλλαγή κωδικού πρόσβασης
        </div>
        <div class="settings-containter">
          <div fxLayout="column" fxLayoutAlign.lt-md="center center center" fxLayoutGap="10px">
            <div>
              <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
                <mat-label>Νέο συνθηματικό</mat-label>
                <input matInput [(ngModel)]="newPassword1"/>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
                <mat-label>Επανάληψη συνθηματικού</mat-label>
                <input matInput [(ngModel)]="newPassword2"/>
              </mat-form-field>
            </div>
            <div class="center">
              <button mat-raised-button color="primary" class="action-btn" [disabled]="!isPasswordValid()" (click)="changePassword()">Αποθήκευση</button>
            </div>
          </div>
        </div> -->
      </div>
 

  </div>
  <div *ngIf="+mode === 3">
    <div fxLayoutAlign="center center center" class="mat-title">Αλλαγή κωδικού πρόσβασης</div>

    <div class="settings-containter">
      <div fxLayout="column" fxLayoutAlign.lt-md="center center center" fxLayoutGap="10px">
        <div>
          <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
            <mat-label>Νέο συνθηματικό</mat-label>
            <input matInput [(ngModel)]="newPassword1"/>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field [ngStyle.lt-md]="{'width':'300px'}">
            <mat-label>Επανάληψη συνθηματικού</mat-label>
            <input matInput [(ngModel)]="newPassword2"/>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button color="primary" [disabled]="!isPasswordValid()" (click)="changePassword()">Αποθήκευση</button>
        </div>
      </div>
    </div>
   
  </div>
</div>
