import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-con-dialog',
  templateUrl: './con-dialog.component.html',
  styleUrls: ['./con-dialog.component.scss']
})
export class ConDialogComponent implements OnInit {

  title = '';
  message = '';
  constructor(private dialog: MatDialog,
              public dialogRef: MatDialogRef<ConDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data.title;
      this.message = data.message;
    }

  ngOnInit(): void {
  }

  confirmDialog() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
