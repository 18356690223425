import {Component, Input, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {AdminService} from '../../services/admin.service';
import {ToastMessagesService} from '../../services/toast-messages.service';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {
  @Input() mode;
  user: any = {};
  newPassword1: string;
  newPassword2: string;
  edit: boolean;
  dataLoaded: boolean;

  constructor(public toastMessagesService: ToastMessagesService,
              private adminService: AdminService,
              private passwordDialog: MatDialog) {
    this.newPassword1 = '';
    this.newPassword2 = '';
    this.edit = false;
    this.dataLoaded = false;
  }

  async ngOnInit() {
    this.user = await this.adminService.getMyInfo();
    this.dataLoaded = true;
    // for (const pr of printCard) {
    //   const model = {
    //     prints: pr,
    //     collapsed: false
    //   };
    // }
  }

  isUserValid() {
    if (this.user.first_name === '' || this.user.last_name === '' || this.user.phone_number === '' || this.user.postal_code === ''
      || this.user.email === '' || this.user.address === '') {
      return false;
    } else {
      return true;
    }
  }

  isPasswordValid(){
    if (this.newPassword1 === '' || this.newPassword2 === ''){
      return false;
    }else{
      return true;
    }
  }

  async changePassword() {
    try {
      if (this.newPassword1 === this.newPassword2) {
        const user = this.user;
        this.user.password = this.newPassword1;
        await this.adminService.updateMyInfo(user);
        this.newPassword2 = '';
        this.newPassword1 = '';
        this.toastMessagesService.toastSuccessMessage('Ο κωδικός χρήστη άλλαξε. Παρακαλούμε εισέλθετε με τα νέα σας στοιχεία.');
        this.adminService.adminLogout();
      } else {
        this.toastMessagesService.toastErrorMessage('Οι κωδικοί πρόσβασης δεν ταιριάζουν');
      }
    } catch (e) {
      console.log(e);
    }
  }

  editing() {
    this.edit = !this.edit;
  }

  async editInfo() {
    try {
      this.edit = !this.edit;
      await this.adminService.updateMyInfo(this.user);
      this.user = await this.adminService.getMyInfo();
    } catch (e) {
      console.log(e);
    }
  }

  cancelEdit() {
    this.edit = !this.edit;
  }

  async changePasswordDialog() {
    const changePasswordDialog = this.passwordDialog.open(ChangePasswordDialogComponent,  );

    changePasswordDialog.afterClosed().subscribe(result => {
      if (result) {
        this.toastMessagesService.toastErrorMessage('Ο κωδικός χρήστη άλλαξε. Παρακαλούμε εισέλθετε με τα νέα σας στοιχεία.');
        this.adminService.adminLogout();
      }
    });
  }
}
