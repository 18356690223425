<div fxLayout="column" style="height: 100%;width: 100%">
    <div fxLayout="column" fxLayoutAlign="center center center" style="width: 100%; height: 100%">
      <mat-card class="card-config mat-elevation-z6" [ngStyle.lt-sm]="{'width':'80%'}">
        <div fxLayout="row" fxLayoutAlign="center">
            <mat-icon>account_circle</mat-icon>
        </div>
        <div fxLayout="row" fxLayoutAlign="center">
            Ανάκτηση κωδικού πρόσβασης
        </div>
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="40">
                <div style="margin-top: 20px">Email:</div>
            </div>
            <div fxLayout="column" fxFlex="60">
                <mat-form-field>
                    <input matInput [(ngModel)]="email" required/>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 100px;">
            <button mat-raised-button (click)="putEmail()">Επιβεβαίωση</button>
        </div>
      </mat-card>
    </div>
</div>
