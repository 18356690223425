<div style="padding: 20px;">

  <div fxLayout="column" style="width: 100%">
    <!--  <div fxLayout="row" fxLayoutAlign="center" style="margin-top: 5%" class="mat-title">Ιστορικό εκτυπώσεων για: {{name}} </div>-->
    <div fxLayout="column" fxHide.lt-md style="width: 100%;  margin-top: 20px">
      <div fxLayout="row" class="mat-title" style=" " fxLayoutAlign="center center center">Εκτυπώσεις του χρήστη {{fullname}}</div>
      <mat-divider ngStyle.lt-sm="margin: 1%"></mat-divider>
      <div fxFlex fxLayout="row">
        <mat-form-field style="width:20%;" *ngIf="dataLoaded">
          <mat-label>Αναζήτηση</mat-label>
          <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
        <div class="export-div">
          <button mat-raised-button class="custom-btn" (click)="exporter.exportTable('xlsx', {fileName: getExportName()})">Εξαγωγή σε excel</button>
        </div>
      </div>
      <div class="mat-elevation-z8" [hidden]="!dataLoaded">
        <table matTableExporter [hiddenColumns]="[2, 6]" mat-table #exporter="matTableExporter" mat-table #table [dataSource]="dataSource" matSort style="width: 100%">
          <ng-container matColumnDef="date_created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ημερομηνία δημιουργίας</th>
            <td mat-cell *matCellDef="let tr"> {{tr.date_created}}</td>
          </ng-container>
          <ng-container matColumnDef="date_completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ημερομηνία ολοκλήρωσης</th>
            <td mat-cell *matCellDef="let tr"> {{tr.date_completed}}</td>
          </ng-container>
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef>Λεπτομέρειες</th>
            <td mat-cell *matCellDef="let tr">
              <mat-icon style="cursor: pointer; margin-left: 25px" matTooltip="Προβολή λεπτομερειών χρήστη" (click)="showDetails(tr)">visibility</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef>Τιμή</th>
            <td mat-cell *matCellDef="let tr"> {{tr.total_price}}€</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Κατάσταση</th>
            <td mat-cell *matCellDef="let tr"> {{tr.status_description}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">Δεν βρέθηκαν αποτελέσματα..</td>
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 15, 20]" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
      </div>
    </div>


      <div fxLayout="column" fxHide.gt-sm *ngIf="dataLoaded">
        <div fxLayout="row" class="mat-title" style="margin-top: 5%" fxLayoutAlign="center">Ιστορικό εκτυπώσεων</div>
        <div fxLayout="column">
          <mat-divider></mat-divider>
          <div fxLayout="row" style="margin: 5px" fxLayoutAlign="space-evenly center">
            <mat-form-field label="Αναζήτηση">
              <input placeholder="Αναζήτηση.." [(ngModel)]="search" matInput/>
            </mat-form-field>
    <!--        <mat-icon style="margin-top: 5px">search</mat-icon>-->
            <!--        (click)="searchUsers()"-->
          </div>
        <div fxLayout="column" *ngIf="prints.length > 0">
        <mat-card *ngFor="let pr of prints | filter: search" style="margin: 10px;border-radius: 20px" class="mat-elevation-z5">
          <div fxLayout="row" class="mat-subheading-2">Εκτύπωση </div>
          <div  *ngIf="dataLoaded">
               <div>Α/Α: {{pr.id}}</div>
               <div>Ημερομηνία δημιουργίας: {{pr.date_created}}</div>
               <div>Ημερομηνία ολοκλήρωσης: {{pr.date_completed}}</div>
               <div>Τιμή εκτύπωσης: {{pr.total_price}}€</div>
               <div>Αποστολή: {{pr.shipping_method_description}}</div>
            <div>Κατάσταση: <span style="font-weight: bold">{{pr.status_description}}</span></div>
              <div fxLayoutAlign="space-evenly center" style="margin: 10px">
                <mat-icon style="margin: 5px;cursor: pointer" (click)="pr.collapsed = !pr.collapsed">visibility</mat-icon>
                <button *ngIf="pr.status_id === 1" mat-raised-button (click)="editRequest(pr.id, pr.status_id)">Επεξεργασία</button>
                <button *ngIf="pr.status_id === 2" mat-raised-button (click)="editRequest(pr.id, pr.status_id)">Έτοιμο</button>
                <button *ngIf="pr.status_id === 3" mat-raised-button disabled>Έτοιμο</button>
              </div>
          </div>
          <mat-card-content *ngIf="pr.collapsed">
            <div fxLayoutAlign="center" style="margin: 10px">Λεπτομέρειες</div>
            <div *ngFor="let option of pr.details" style="margin: 10px">
              <div>
                <div>
                  <div>Αρχείο: {{option.file_name}}</div>
                  <mat-icon>download</mat-icon>
                </div>
                <div>Προσανατολισμός σελίδας: {{option.page_orientation_description}}</div>
                <div>Μορφοποίηση σελίδας: {{option.page_side_description}}</div>
                <div *ngFor="let opt of option.properties">{{opt.category_description}}: {{opt.description}}</div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        </div>
        <div *ngIf="userPrints.length === 0">
          <div style="margin: 20px" fxLayoutAlign="center">Δεν βρέθηκαν εκτυπώσεις..</div>
        </div>
      </div>
    </div>
    </div>
</div>


  <div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 100%"><mat-spinner></mat-spinner></div>
