
<div>
    
    <h1 mat-dialog-title>
        <div class="header">
            <span style="font-size: larger; margin-right: 20px;">Αλλαγή Τελικής Τιμής</span>
            <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
        </div>
    </h1>
    
    <div class="container">
        <div class="fw">
            <div class="mat-subheading-1" fxLayoutAlign="start">
            <mat-icon class="icon-config info-color" style="margin-right: 5px;">info</mat-icon>Συμπληρώστε μία νέα κατηγορία. 
            </div>
        </div>
        <form [formGroup]="form" style="width: 100%"> 
            <div class="fw" style="margin-top: 15px;">
                <mat-form-field  class="fw">
                    <mat-label>Νέα Τελική τιμή</mat-label>
                    <input matInput formControlName="final_total" type="number" />
                </mat-form-field>
              
            </div>
            <mat-form-field style='width: 100%;'>
                <mat-label>Περιγραφή</mat-label>
                <textarea  matInput formControlName="final_total_description" type="text"></textarea>
            </mat-form-field>
        </form>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button class="custom-btn" [disabled]="!form.valid" (click)="submit()">Επιβεβαίωση</button>
</div>