<div fxLayout="column">
    <h1 mat-dialog-title>
        <div class="header">
            <div style="font-size: larg;" fxLayoutAlign="center center center"> {{title}} </div>
            <mat-icon style="cursor: pointer; margin-bottom: 10px;" (click)="closeDialog()">clear</mat-icon>
        </div>
    </h1>
 

    <div fxLayout="row" fxLayoutAlign="start center">
        <h3>{{message}}</h3>
    </div>
 
</div>
<div mat-dialog-actions align="end">
    <button (click)="closeDialog()" class="custon-btn-close" mat-raised-button>Ακύρωση</button>
    <button (click)="confirmDialog()" mat-raised-button class="custom-btn">Επιβεβαίωση</button>
</div>