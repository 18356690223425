import { NewShippingsDialogComponent } from './../new-shippings-dialog/new-shippings-dialog.component';
import { EditShippingsDialogComponent } from './../edit-shippings-dialog/edit-shippings-dialog.component';
import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-shippings',
  templateUrl: './edit-shippings.component.html',
  styleUrls: ['./edit-shippings.component.scss']
})
export class EditShippingsComponent implements OnInit {

  dataLoaded = false;
  index = -1;
  shippings = [];

  constructor(private commonService: CommonService, private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    await this.loadShippings();
  }

  async loadShippings() {
    try {
      const response = await this.commonService.getRequest('shipping');
      this.shippings = response;
      this.dataLoaded = true;
    } catch (error) {

    }
  }


  async newShippingModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      description: '',
      price: 0,
      is_active: false,
      is_new: true
    };
    const dialogRef = this.dialog.open(EditShippingsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.loadShippings();
      }
    });
  }

  canDelete(item) {
    return false;
  }

  isEdit(index) {
    return index === this.index;
  }

  async editShippingDialog(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = item;
    const dialogRef = this.dialog.open(EditShippingsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.loadShippings();
        item = data;
      }
    });
  }

}
