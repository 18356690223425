<div fxLayout="column" fxLayoutAlign="center center center" [hidden]="!dataLoaded" style="width: 100%">
  <div fxLayout="row" fxLayoutAlign="center center center" style="margin: 20px" class="mat-title">
    Πληροφορίες χρηστών
  </div>
  <mat-divider style="width: 100%;"></mat-divider>
  
<!--  responsive-->
  <div fxFlex fxLayout="row" fxHide.gt-sm fxLayout.xs="column" *ngIf="dataLoaded">
    <div fxLayout="column">
      <mat-divider></mat-divider>
      <div fxLayout="row" style="margin: 5px" fxLayoutAlign="space-evenly center">
        <mat-form-field label="Αναζήτηση">
          <input placeholder="Αναζήτηση.."  [(ngModel)]="search" matInput/>
        </mat-form-field>
<!--        <mat-icon style="margin-top: 5px">search</mat-icon>-->
<!--        (click)="searchUsers()"-->
      </div>

    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign.xs="center center center" style="margin: 20px" *ngIf="!searchEnabled || search ===''">
        <div *ngFor="let u of card | filter: search">
          <app-users-info [info]="u"></app-users-info>
        </div>
    </div>
  </div>

<div fxLayout="column" *ngIf="searchEnabled && search !=='' ">
    <div *ngFor="let search of searchItems">
      <app-users-info [info]="search"></app-users-info>
    </div>
  </div>
</div>



<!--desktop-->
  <div fxFlex fxLayout="column" style="width: 100%; padding:55px" fxHide.lt-md>
    <div fxFlex fxLayout="row" *ngIf="dataLoaded">
      <mat-form-field style="width:20%;">
        <mat-label>Αναζήτηση</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
      <div class="export-div">
        <button mat-raised-button class="custom-btn" (click)="exporter.exportTable('xlsx', {fileName: 'Λίστα Χρηστών'})">Εξαγωγή σε excel</button>
      </div>
    </div>

    <div class="mat-elevation-z8" fxFlex [hidden]="noData">
    <table #exporter="matTableExporter" matTableExporter [hiddenColumns]="[6, 7]" mat-table [dataSource]="dataSource"  matSort style="width:100%">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Α/Α </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="first_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Όνομα </th>
        <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
      </ng-container>

      <ng-container matColumnDef="last_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Επώνυμο </th>
        <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Τηλέφωνο </th>
        <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Διεύθυνση </th>
        <td mat-cell *matCellDef="let element"> {{element.address}}, {{element.postal_code}} </td>
      </ng-container>

      <ng-container matColumnDef="prints">
        <th mat-header-cell *matHeaderCellDef> Εκτυπώσεις </th>
        <td mat-cell *matCellDef="let element"> <mat-icon style="cursor: pointer; margin-left: 20px" matTooltip="Προβολή εκτυπώσσεων χρήστη" (click)="getUserPrintOrders(element.id)">print</mat-icon> </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Ενέργειες </th>
        <td mat-cell *matCellDef="let element"> <mat-icon matTooltip="Ορισμός ως Διαχειριστή" (click)="setUserAdmin(element)" style="cursor: pointer; margin-left: 20px">account_box</mat-icon> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">Δεν βρέθηκαν αποτελέσματα..</td>
      </tr>
    </table>
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div fxLayout="row" *ngIf="dataLoaded && noData"> Δεν υπάρχουν δεδομένα χρηστών</div>
</div>
<div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 40vh"><mat-spinner></mat-spinner></div>
