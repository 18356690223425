import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email = '';

  constructor(private toastMessagesService: ToastMessagesService,
              private commonService: CommonService,
              private router: Router) { }

  ngOnInit(): void {
  }

  /**
   * Description: sends put request with the submitted email value in order to
   * retrieve the login data
   */
  async putEmail() {
    if (!this.email) {
      this.toastMessagesService.toastErrorMessage('Παρακαλώ προσθέστε ένα έγκυρο email.');
    } else {

      const body = {
        email: this.email,
        admin_request: 1
      };

      try {
        await this.commonService.putRequest(`user/forgot`, body);
        this.toastMessagesService.toastSuccessMessage('Σας στάλθηκε email με οδηγίες για την ανάκτηση του κωδικού πρόσβασης.');
        await this.router.navigateByUrl('/');
      } catch (error) {
        this.toastMessagesService.toastErrorMessage(error.error.message);
      }
    }
  }

}
