import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ConDialogComponent} from '../con-dialog/con-dialog.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  isMatMenuOpen: boolean;
  userName: any;
  firstName: string;
  lastName: string;

  constructor(private adminService: AdminService, private dialog: MatDialog) { }

  async ngOnInit()  {
    const user = await this.adminService.getMyInfo();
    // let me = localStorage.getItem('user');
    // me = JSON.parse(me);

    this.userName = user.username;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    // this.firstName = me.first_name;
    // this.lastName = me.last_name;
  }

  myInfo(){

  }

  async adminLogout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Επιβεβαίωση Αποσύνδεσης',
      message: 'Είστε σίγουρος ότι θέλετε να αποσυνδεθείτε;'
    };
    const dialog = this.dialog.open(ConDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(async result => {
      if (result) {
        try {
          await this.adminService.adminLogout();
        } catch (e) {
          console.log(e);
        }
      }
    });

  }


  getFullname() {
    return this.firstName + ' ' + this.lastName;
  }
}
