import { ProgressSpinnerDialogService } from './../../services/progress-spinner-dialog.service';
import { CommonService } from './../../services/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-total-price-dialog',
  templateUrl: './total-price-dialog.component.html',
  styleUrls: ['./total-price-dialog.component.scss']
})
export class TotalPriceDialogComponent implements OnInit {

  form;

  constructor(public dialogRef: MatDialogRef<TotalPriceDialogComponent>,
              public formBuilder: FormBuilder,
              private commonService: CommonService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      final_total: [
        this.data.request.final_total ? this.data.request.final_total : this.data.request.price,
        Validators.compose([
          Validators.required
        ]),
      ],
      final_total_description: [
        this.data.request.final_total_description,
        Validators.compose([
          Validators.required
        ]),
      ],
    });
  }

  async submit() {
    try {
      this.progressSpinnerDialogService.show();
      const response = this.commonService.putRequest(`request/final-total/${this.data.request.id}`, this.form.value);
      this.dialogRef.close(response);

    } catch (error) {

    } finally {
      this.progressSpinnerDialogService.end();
    }
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(false);
  }

}
