<div fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="center center center" class="mat-title main-config">
    Εκτυπώσεις
  </div>
  <mat-divider style="width: 100%;"></mat-divider>
  <mat-tab-group (selectedTabChange)="checkTab($event)" [dynamicHeight]="true">
    <mat-tab label="Σε αναμονή">
      <!-- <div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 60vh">
        <mat-spinner></mat-spinner>
      </div> -->
      <app-main-details-tab [status]="1" *ngIf="dataLoaded" #pendingTable (updated)="reload()" fxLayoutAlign.lt-sm="center center center" [info]="pendingData"></app-main-details-tab>
      <app-main-details-tab [status]="1" *ngIf="!dataLoaded" #pendingTable (updated)="reload()" fxLayoutAlign.lt-sm="center center center" [info]="pendingData"></app-main-details-tab>

    </mat-tab>
    <mat-tab label="Ολοκληρωμένες">
      <div *ngIf="dataLoaded && !complete_dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 60vh">
        <mat-spinner></mat-spinner>
      </div>
      <div *ngIf="!complete_dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 60vh">Δεν βρέθηκαν ολοκληρωμένες εκτυπώσεις</div>
      <app-main-details-tab [status]="3" *ngIf="complete_dataLoaded" #completeTable (updated)="reload()" fxLayoutAlign.lt-sm="center center center" [info]="data"></app-main-details-tab>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="displayVersionUpdates" style="position: absolute; bottom: 2%; right: 2%; z-index: 999;">
  <mat-card style="height: 550px; width: 300px; overflow-y: auto;">
    <mat-card-title>
     <span fxFlex> Version 1.0</span>
      <mat-icon style="cursor: pointer" (click)="displayVersionUpdates=false">close</mat-icon>
    </mat-card-title>
    <mat-card-content>
      1. Login: <br>
      1.1 Αλλαγή UI να είναι ίδιο με του client <br>
      1.2 Εάν κάποιο από τα πεδία δεν είναι συμπληρωμένο δε μπορείς να πατήσεις σύνδεση <br>
      2. Τροποποίηση του menu UI <br>
      3. Αρχική: <br>
      3.1 τα κουμπιά στις ενέργειες έχουν ένα μέγεθος <br>
      3.2 Υλοποίηση αναζήτησης σε mobile view <br>
      3.3 Στις λεπτομέρειες εκτύπωσης μπορείς να κατεβάσεις αρχείο <br>
      4. Επιλογές εκτύπωσης: <br>
      4.1 Όταν σβήνουμε κατηγορία αφαιρείται απευθείας <br>
      4.2 overflow: auto όταν οι επιλογές μίας κατηγορίας είναι περισσότερες <br>
      5. Χρήστες: <br>
      5.1 Προσθήκη στήλη ενέργειες όπου μπορεί ένας χρήστης να οριστεί ως διαχειριστής <br>
      5.2 Υλοποίηση αναζήτησης σε mobile view <br>
      5.3 Εκτυπώσεις per user(εμφανίζονται κάνοντας κλικ στον εκτυπωτή μέσα από τη λίστα των χρηστών): <br>
      5.4 Υλοποίηση αναζήτησης σε mobile view <br>
      6. Προστέθηκε το component Προφίλ Χρήστη (υπήρχε μόνο στην client εφαρμογή): <br>
      6.1 Διόρθωση UI στην εμφάνιση των πληροφοριών του χρήστη(και στο mobile view) <br>
      6.2 Στην επεξεργασία πληροφοριών εάν σβήσεις το value από ένα πεδίο δε μπορείς να πατήσεις αποθήκευση <br>
      6.3 Στην Αλλαγή Κωδικού Πρόσβασης δε μπορείς να επιλέξεις αποθήκευση εάν κάποιο πεδίο δεν έχει συμπληρωθεί <br>
      7. Εμφάνιση μηνυμάτων σε snackBars (υλοποιήθηκε κοινό για όλες τις ειδοποιήσεις προς το χρήστη)
    </mat-card-content>
  </mat-card>
</div>
