import { AnnouncementDialogComponent } from './components/announcement-dialog/announcement-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MainComponent } from './components/main/main.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';
import { UsersComponent } from './components/users/users.component';
import {MatDialogModule} from '@angular/material/dialog';
import { OptionsDialogComponent } from './components/options-dialog/options-dialog.component';
import {MatTabsModule} from '@angular/material/tabs';
import { UsersInfoComponent } from './components/users-info/users-info.component';
import { UsersPrintComponent } from './components/users-print/users-print.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MainDetailsTabComponent } from './components/main-details-tab/main-details-tab.component';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {CustomPaginator} from './paginatorConfiguration/paginatorConfiguration';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { EditCategoryComponent } from './components/edit-category/edit-category.component';
import { AddPropertyDialogComponent } from './components/add-property-dialog/add-property-dialog.component';
import { EditPropertyDialogComponent } from './components/edit-property-dialog/edit-property-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {InterceptService} from './interceptors/intercept.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { EditCategoryDetailsComponent } from './components/edit-category-details/edit-category-details.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import { ConfirmDialogComponent } from './components/shared/confirm-dialog/confirm-dialog.component';
import {FilterPipe} from './filter.pipe';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ProgressSpinnerDialogComponent } from './components/progress-spinner-dialog/progress-spinner-dialog.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';
import { NewCategoryDialogComponent } from './components/new-category-dialog/new-category-dialog.component';
import { EditCategoryDialogComponent } from './components/edit-category-dialog/edit-category-dialog.component';
import { PaymentDialogComponent } from './components/payment-dialog/payment-dialog.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { ShippingsComponent } from './components/shippings/shippings.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsDetailsComponent } from './components/settings-details/settings-details.component';
import { EditShippingsComponent } from './components/edit-shippings/edit-shippings.component';
import { EditShippingsDialogComponent } from './components/edit-shippings-dialog/edit-shippings-dialog.component';
import { NewShippingsDialogComponent } from './components/new-shippings-dialog/new-shippings-dialog.component';
import { EditPaymentsComponent } from './components/edit-payments/edit-payments.component';
import { EditPaymentsDialogComponent } from './components/edit-payments-dialog/edit-payments-dialog.component';
import { ConDialogComponent } from './components/con-dialog/con-dialog.component';
import { ChartsModule } from 'ng2-charts';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {MatSelectModule} from '@angular/material/select';
import { EditCommunicationComponent } from './components/edit-communication/edit-communication.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { EditTermsComponent } from './components/edit-terms/edit-terms.component';
import { TotalPriceDialogComponent } from './components/total-price-dialog/total-price-dialog.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatTableExporterModule } from 'mat-table-exporter';
import { HeaderComponent } from './components/header/header.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    NavBarComponent,
    UsersComponent,
    OptionsDialogComponent,
    UsersInfoComponent,
    UsersPrintComponent,
    MainDetailsTabComponent,
    EditCategoryComponent,
    AddPropertyDialogComponent,
    EditPropertyDialogComponent,
    EditCategoryDetailsComponent,
    ProfileComponent,
    ProfileDetailsComponent,
    ConfirmDialogComponent,
    FilterPipe,
    ForgotPasswordComponent,
    ProgressSpinnerDialogComponent,
    ForgotPasswordDialogComponent,
    NewCategoryDialogComponent,
    EditCategoryDialogComponent,
    PaymentDialogComponent,
    DashboardComponent,
    PaymentsComponent,
    ShippingsComponent,
    SettingsComponent,
    SettingsDetailsComponent,
    EditShippingsComponent,
    EditShippingsDialogComponent,
    NewShippingsDialogComponent,
    EditPaymentsComponent,
    EditPaymentsDialogComponent,
    ConDialogComponent,
    ChangePasswordDialogComponent,
    EditCommunicationComponent,
    AnnouncementsComponent,
    AnnouncementDialogComponent,
    EditTermsComponent,
    TotalPriceDialogComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatTabsModule,
    FormsModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    ChartsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    MatSelectModule,
    AngularEditorModule,
    MatTableExporterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true
    }, 
    {
      provide: MatPaginatorIntl, useValue: CustomPaginator()
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    }
  ],
  exports: [
    ProfileDetailsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
