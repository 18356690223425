import { ToastMessagesService } from './../../services/toast-messages.service';
import { CommonService } from './../../services/common.service';
import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {OptionsDialogComponent} from '../options-dialog/options-dialog.component';
import {TransactionService} from '../../services/transaction.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';

@Component({
  selector: 'app-users-print',
  templateUrl: './users-print.component.html',
  styleUrls: ['./users-print.component.scss']
})
export class UsersPrintComponent implements OnInit, AfterViewInit {
  id: number;
  userPrints: any;
  prints = [];
  dataLoaded: boolean;
  mode: string;
  search: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['date_created', 'date_completed', 'options', 'price', 'status'];
  fullname: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private dialog: MatDialog,
              private router: Router,
              private transactionService: TransactionService,
              private commonService: CommonService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private toastMessagesService: ToastMessagesService) {
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      await this.initCalls();
    } catch (e) {
      console.log(e);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async initCalls() {
    try {
      this.route.queryParams.subscribe(params => {
        this.id = +params.id;
      });

      const response = await this.userService.getUsers();

      response.forEach(element => {
        if (element.id === this.id) {
          this.fullname = element.username;
        }
      });

      this.userPrints = await this.userService.getUserPrints(this.id);
      if (this.userPrints.length > 0) {
        this.dataSource.data = this.userPrints;
      }
      for (const pr of this.userPrints) {
        const model = {
          print: pr,
          collapsed: false
        };
        this.prints = this.userPrints;
      }
      this.dataLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  applyFilter(value: any) {
    this.dataSource.filter = value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async showDetails(element) {

    let options = [];
    try {
      const response = await this.commonService.getRequest(`request/details/${element.id}`);
      options = response;
    } catch (error) {

    }

    const item = {
      options,
      dateC: 0
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = item;
    const myDialog = this.dialog.open(OptionsDialogComponent, dialogConfig);
  }

  goBack() {
    this.router.navigate(['/home/users']);
  }

  async editRequest(id, status) {
    try {
      // let mode = ``;
      // let mode = '';
      if (status === 1) {
        // mode = `edit`;
        this.mode = 'complete';
      }
      // else if (status === 2) {
      //   // mode = `complete`;
      //   this.mode = 'complete';
      // }
      const item = {
        id: id
      };
      this.progressSpinnerDialogService.show();
      await this.transactionService.editRequest(item, this.mode);
      await this.initCalls();
      this.toastMessagesService.toastSuccessMessage('Η εκτύπωση αποθηκεύτηκε επιτυχώς');
      this.progressSpinnerDialogService.end();
    } catch (e) {
      console.log(e);
      this.progressSpinnerDialogService.end();
    }
  }

  getExportName() {
    return `Λίστα Εκτυπώσεων του χρήστη ${this.fullname}`;
  }
}

