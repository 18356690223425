import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddPropertyDialogComponent } from '../add-property-dialog/add-property-dialog.component';

@Component({
  selector: 'app-edit-category-dialog',
  templateUrl: './edit-category-dialog.component.html',
  styleUrls: ['./edit-category-dialog.component.scss']
})
export class EditCategoryDialogComponent implements OnInit {

  value = '';
  details = '';
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddPropertyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              public formBuilder: FormBuilder) {
      this.value = data.description;
      this.details = data.details;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      value: [
        '',
        Validators.compose([
          Validators.required
        ]),
      ],
      details: [
        '',
        Validators.compose([
          Validators.required
        ]),
      ],
    });
  }

  submit() {
    this.dialogRef.close({ value: this.value, details: this.details });
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }
}
