<div fxLayout="column" style="padding: 55px" fxHide.lt-md>
  <div *ngIf="!loaded" fxLayoutAlign="center center center" style="width: 100%;height: 60vh">
    <mat-spinner></mat-spinner>
  </div>
    <div fxFlex fxLayout="row" *ngIf="loaded">
      <mat-form-field style="width:20%;">
        <mat-label>Αναζήτηση</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
      <div class="export-div">
        <button mat-raised-button class="custom-btn" (click)="exporter.exportTable('xlsx', {fileName: getExportName()})">Εξαγωγή σε excel</button>
        <!-- <button mat-raised-button class="custom-btn" (click)="export()">Εξαγωγή σε excel</button> -->
      </div>
    </div>
    <div class="mat-elevation-z8" [ngClass]="{'hide': !loaded}">
      
      <table matTableExporter [hiddenColumns]="hiddenColumns" mat-table #exporter="matTableExporter"  #table [dataSource]="dataSource" matSort style="width: 100%">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header >Α/Α</th>
          <td mat-cell *matCellDef="let tr"> {{tr.id}}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ημερομηνία Δημιουργίας</th>
          <td mat-cell *matCellDef="let tr"> {{ tr.date | date: 'dd-MM-yyyy' }} </td>
        </ng-container>
        <ng-container matColumnDef="date_completed" *ngIf="status === 3" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ημερομηνία Ολοκλήρωσης</th>
          <td mat-cell *matCellDef="let tr"> {{ tr.date_completed | date: 'dd-MM-yyyy' }} </td>
        </ng-container>
        <ng-container matColumnDef="shipping">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Αποστολή</th>
          <td mat-cell *matCellDef="let tr"> {{tr.shipping}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Χρήστης</th>
          <td mat-cell *matCellDef="let tr"> {{tr.name}}</td>
        </ng-container>
        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef> Λεπτομέρειες </th>
          <td mat-cell *matCellDef="let tr">
            <mat-icon style="cursor: pointer; margin-left: 25px" matTooltip="Προβολή λεπτομερειών" (click)="showDetails(tr.id, tr.dateC, tr.address, tr.postal_code, tr.phone_number)">visibility</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="payment">
          <th mat-header-cell *matHeaderCellDef> Στοιχεία Τιμολόγησης </th>
          <td mat-cell *matCellDef="let tr">
            <mat-icon style="cursor: pointer; margin-left: 40px" matTooltip="Προβολή λεπτομερειών" (click)="showPayment(tr)">description</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Τιμή</th>
          <td mat-cell *matCellDef="let tr"> {{tr.price}}€</td>
        </ng-container>
        <!-- <ng-container matColumnDef="final_price">
          <th mat-header-cell *matHeaderCellDef>Τελική Τιμή</th>
          <td mat-cell *matCellDef="let tr"> 
            <div style="margin-left: 20px;" [matTooltip]="tr.final_total_description">
              {{tr.final_total}}€
            </div>
          </td>
        </ng-container> -->
        <ng-container matColumnDef="total_pages">
          <th mat-header-cell *matHeaderCellDef>Συνολικές Σελίδες</th>
          <td mat-cell *matCellDef="let tr">
            <div style="margin-left: 20px;" [matTooltip]="tr.total_pages">
              {{tr.total_pages}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="payment_description">
          <th mat-header-cell *matHeaderCellDef>Τρόπος Πληρωμής</th>
          <td mat-cell *matCellDef="let tr">
            <div style="margin-left: 20px;" [matTooltip]="tr.payment_description">
              {{tr.payment_description}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paid_description">
          <th mat-header-cell [hidden]="true" *matHeaderCellDef>Κατάσταση Πληρωμής</th>
          <td mat-cell [hidden]="true" *matCellDef="let tr">
            <div style="margin-left: 20px;" [matTooltip]="tr.paid_description">
              {{tr.paid_description}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="paid">
          <th mat-header-cell *matHeaderCellDef>Κατάσταση Πληρωμής</th>
          <td mat-cell *matCellDef="let tr">
            <mat-icon *ngIf="tr.is_paid === 0" matTooltip="Επιβεβαίωση Πληρωμής" style="cursor: pointer; margin-left: 45px" (click)="pay(tr)">money_off</mat-icon>
            <mat-icon *ngIf="tr.is_paid === 1" matTooltip="Πληρωμένη" style="margin-left: 45px" >attach_money</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="state" *ngIf="status === 1">
          <th mat-header-cell *matHeaderCellDef>
            <div style="text-align: center; width: 80px;">
              Ενέργεια
            </div>
          </th>
          <td mat-cell *matCellDef="let tr">
            <div style="text-align: center; width: 80px;">
              <!-- <mat-icon matTooltip="Αλλαγή Τελικής Τιμής" style="cursor: pointer; margin-left: 15px" (click)="editRequestTotalPrice(tr)">edit</mat-icon> -->

              <mat-icon *ngIf="tr.status_id === 1" matTooltip="Ολοκλήρωση" style="cursor: pointer; margin-left: 15px" (click)="editRequestConfirm(tr.id, tr.status_id)">done_all</mat-icon>
    <!--          <mat-icon *ngIf="tr.status_id === 2" matTooltip="Έτοιμο" style="cursor: pointer; margin-left: 15px" (click)="editRequest(tr.id, tr.status_id)">done_all</mat-icon>-->
              <mat-icon *ngIf="tr.status_id === 3" matTooltip="Ολοκληρωμένη" style="margin-left: 15px" >done_all</mat-icon>
            </div>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="state" *ngIf="status === 3">
          <th mat-header-cell *matHeaderCellDef>Ενέργεια</th>
          <td mat-cell *matCellDef="let tr">
            <mat-icon matTooltip="Αλλαγή Τελικής Τιμής" style="cursor: pointer; margin-left: 15px" (click)="editRequestTotalPrice(tr)">edit</mat-icon>
          </td>
        </ng-container> -->
        <ng-container *ngIf="status === 1">
          <tr mat-header-row *matHeaderRowDef="pendingDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: pendingDisplayedColumns;"></tr>
  
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">Δεν βρέθηκαν αποτελέσματα..</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="status === 3">
          <tr mat-header-row *matHeaderRowDef="completeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: completeDisplayedColumns;"></tr>
  
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">Δεν βρέθηκαν αποτελέσματα..</td>
          </tr>
        </ng-container>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 15, 20]" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
    </div>
</div>
 
<div fxLayout="row" *ngIf="printTable.length > 0" fxLayout.lt-md="column" fxHide.gt-sm style="height: 70%">
  <div fxLayout="row" style="margin: 5px" fxLayoutAlign="space-evenly center">
    <mat-form-field label="Αναζήτηση">
      <input placeholder="Αναζήτηση.." [(ngModel)]="search" matInput/>
    </mat-form-field>
<!--      <mat-icon style="margin-top: 5px" (click)="searchUsers()">search</mat-icon>-->
  </div>
  <div *ngFor="let print of printTable | filter: search">
    <mat-card style="margin: 10px;border-radius: 20px" class="mat-elevation-z5">
      <div fxLayout="row" class="mat-subheading-2" fxLayoutAlign="center center center">
        Εκτύπωση
      </div>
      <div fxLayout="row">
      Α/Α: {{print.id}}
      </div>
      <div fxLayout="row">
        Ημερομηνία δημιουργίας: {{print.date}}
      </div>
      <div fxLayout="row">
        Αποστολή: {{print.shipping}}
      </div>
      <div fxLayout="row">
        Κατάσταση: <span style="font-weight: bold">{{print.status}}</span>
      </div>
      <div fxLayout="row">
        Τιμή εκτύπωσης: {{print.price}}€
      </div>
      <div fxLayout="row">
        Τελική Τιμή εκτύπωσης: {{print.final_price ? print.final_price : print.price}}€
      </div>
      <div fxLayout="row">
        Χρήστης: {{print.name}}
      </div>
      <div fxLayout="row">
        Διεύθυνση: {{print.addess}}
      </div><div fxLayout="row">
        ΤΚ: {{print.postal_code}}
      </div>
      <div fxLayout="row">
        Τηλέφωνο: {{print.phone_number}}
      </div>
      <div fxLayout="row">
        Κατάσταση πληρωμής: {{print.paid_description}}
      </div>
 
      <div fxLayout="row" fxLayoutAlign="space-evenly center" style="margin: 10px">
        <button mat-button (click)="print.collapsed = !print.collapsed"><mat-icon>visibility</mat-icon></button>
        <!-- <button *ngIf="print.status_id === 1" mat-raised-button (click)="editRequest(print.id, print.status_id)">Επεξεργασία</button> -->
        
        <mat-icon *ngIf="print.is_paid === 0" matTooltip="Επιβεβαίωση πληρωμής" style="cursor: pointer; margin-left: 15px" (click)="pay(print)">money_off</mat-icon>
        <mat-icon *ngIf="print.is_paid === 1" matTooltip="Πληρωμένη" style="margin-left: 15px" >attach_money</mat-icon>
        
        <button *ngIf="print.status_id === 1" color='primary'  mat-raised-button (click)="editRequest(print.id, print.status_id)">Έτοιμο</button>
        <button *ngIf="print.status_id === 3" color='primary' mat-raised-button disabled>Έτοιμο</button>

      </div>

      <mat-card-content *ngIf="print.collapsed">
        <div fxLayoutAlign="center" style="margin: 10px">Λεπτομέρειες</div>
        <div *ngFor="let option of print.printOptions" style="margin: 10px">
          <div>
          <div>
            <div>Αρχείο: {{option.file_name}}</div>
            <button mat-raised-button color="primary" (click)="downloadFile(option.file_id, option.file_name)"><mat-icon>download</mat-icon></button>
            </div>
          <div>Προσανατολισμός σελίδας: {{option.page_orientation_description}}</div>
          <div>Μορφοποίηση σελίδας: {{option.page_side_description}}</div>
          <div *ngFor="let opt of option.properties">{{opt.category_description}}: {{opt.description}}</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
