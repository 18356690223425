<div fxLayout="column" *ngIf="dataLoaded">
  <div fxLayout="row" class="mat-title" style="margin: 20px" fxLayoutAlign="center center center">Επεξεργασία επιλογών εκτύπωσης</div>
  <mat-divider ngStyle.lt-sm="margin: 1%"></mat-divider>
  <mat-tab-group>
    <mat-tab label="Κύριες">
      <app-edit-category-details (deleted)="reloadOtherDelete($event)" (created)="reloadOther($event)" (updated)="reloadOther($event, true)" fxLayoutAlign.lt-sm="center center center" [info]="otherCategories" [mode]="1"></app-edit-category-details>
    </mat-tab>
    <mat-tab label="Λοιπές">
      <app-edit-category-details (deleted)="reloadMainDelete($event)" (created)="reloadMain($event)" (updated)="reloadMain($event, true)" fxLayoutAlign.lt-sm="center center center" [info]="mainCategories" [mode]="0"></app-edit-category-details>
    </mat-tab>
  </mat-tab-group>
</div>
<div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 100%; margin-top: 100px;"><mat-spinner></mat-spinner></div>
