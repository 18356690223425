import { AddPropertyDialogComponent } from './../add-property-dialog/add-property-dialog.component';
import { element } from 'protractor';
import { EditCategoryDialogComponent } from './../edit-category-dialog/edit-category-dialog.component';
import { NewCategoryDialogComponent } from './../new-category-dialog/new-category-dialog.component';
import { ProgressSpinnerDialogService } from './../../services/progress-spinner-dialog.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditPropertyDialogComponent} from '../edit-property-dialog/edit-property-dialog.component';
import {EditService} from '../../services/edit.service';
import {ToastMessagesService} from '../../services/toast-messages.service';

@Component({
  selector: 'app-edit-category-details',
  templateUrl: './edit-category-details.component.html',
  styleUrls: ['./edit-category-details.component.scss']
})
export class EditCategoryDetailsComponent implements OnInit {
  @Input() info;
  @Input() mode;
  @Output() updated = new EventEmitter();
  @Output() created = new EventEmitter();
  @Output() deleted = new EventEmitter();
  categories: any;
  properties: any;
  propertiesModel: any;
  newCategory: string;
  newDetails: string;
  newProperty: string;
  dataLoaded: boolean;
  displayedColumns: string[] = ['description', 'status', 'action', 'delete'];

  constructor(private editService: EditService,
              private dialog: MatDialog,
              private newCategoryDialog: MatDialog,
              public toastMessagesService: ToastMessagesService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.newCategory = '';
    this.newDetails = '';
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      this.dataLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  isButtonDisabled() {
    return this.newCategory.length === 0;
  }

  async addNewCategory(newCatecory = null, newDetails = null) {
    try {

      let model;

      if (newCatecory !== null && newDetails !== null) {
        model = {
          description: newCatecory,
          details: newDetails,
          is_main: +this.mode
        };
      } else {
        model = {
          description: this.newCategory,
          details: this.newDetails,
          is_main: +this.mode
        };
      }

      this.progressSpinnerDialogService.show();
      await this.editService.addNewCategory(model);
      this.newCategory = '';
      this.created.emit();

    } catch (e) {
      console.log(e);
      this.progressSpinnerDialogService.end();
    }
  }

  addNewProperty(id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = id;
    dialogConfig.width = '50%';
    const dialogRef = this.dialog.open(AddPropertyDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.created.emit({property: true});
        // this.refresh();
      }
    });
  }

  editProperty(option) {
    try {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = option;
      dialogConfig.width = '50%';
      const dialogRef = this.dialog.open(EditPropertyDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.updated.emit({property: true});
          // this.refresh();
        }
      });
    //   const model = {
    //     id: option.id,
    //     description: option.description,
    //     price: parseFloat(option.price).toFixed(2),
    //     is_active: option.is_active,
    //     category_id: option.category_id
    //   };
    //   this.editPropertyDialog(model);
     } catch (e) {
      console.log(e);
    }
  }

  editPropertyDialog(model) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = model;
    dialogConfig.width = '25%';

    const dialogRef = this.dialog.open(EditPropertyDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.info.forEach(category => {
          category.properties.forEach(element => {
            if (element.id === data.id) {
              this.updated.emit({
                id: data.id,
                new_object: data,
                property: true
              });
            }
          });
        });

      }
    });
  }

  editCategoryDialog(category) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = category;
    dialogConfig.width = '25%';

    const dialogRef = this.dialog.open(EditCategoryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data.value) {
        this.editCategory(category, data.value, data.details);
      }
    });
  }

  refresh(): void {
    window.location.reload();
  }

  async editCategory(item, value = null, details = null) {
    try {

      let model;

      if (value !== null && details !== null) {
        model = {
          id: item.id,
          description: value,
          details: details,
          is_main: +this.mode
        };
      } else {
        model = {
          id: item.id,
          description: item.description,
          details: item.details,
          is_main: +this.mode
        };
      }
      this.progressSpinnerDialogService.show();
      await this.editService.editCategory(model);

      if (value !== null) {
        item.description = value;
      }

      if (value !== null) {
        item.details = details;
      }

      this.progressSpinnerDialogService.end();

      this.toastMessagesService.toastSuccessMessage('Η κατηγορία αποθηκεύτηκε επιτυχώς.');
    } catch (e) {
      this.progressSpinnerDialogService.end();

      console.log(e);
    }
  }


  canDelete(item) {
    if (item.properties.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  async deleteCategory(item) {

    try {
      if (item.properties.length === 0) {
        this.progressSpinnerDialogService.show();
        await this.editService.deleteCategory(item.id);
        this.info.splice(this.info.indexOf(item), 1);
        this.progressSpinnerDialogService.end();
        this.toastMessagesService.toastSuccessMessage('Η κατηγορία διεγράφη επιτυχώς.');

      } else {
        this.toastMessagesService.toastErrorMessage('Κατηγορία που έχει επιλογές δεν μπορεί να διαγραφεί');
      }
    } catch (e) {
      console.log(e);
    }
  }

  async deleteProperty(item, element) {
    try {
      this.progressSpinnerDialogService.show();
      await this.editService.deleteProperty(element.id);

      this.progressSpinnerDialogService.end();
      this.toastMessagesService.toastSuccessMessage('Η επιλογή διεγράφη επιτυχώς.');

      this.deleted.emit({
        property: true
      });
    } catch (e) {
      this.progressSpinnerDialogService.end();

      this.toastMessagesService.toastSuccessMessage(e.error.message);
    }
  }

  async newCategoryModal() {
    const newCategoryDialog = this.newCategoryDialog.open(NewCategoryDialogComponent, {width: '25%' });

    newCategoryDialog.afterClosed().subscribe(result => {
      if (result.newCategory && result.newDetails) {
        this.addNewCategory(result.newCategory, result.newDetails);
      }
    });
  }
}
