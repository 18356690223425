
<h1 mat-dialog-title>
    <div class="header">
        <span style="font-size: larger; margin-right: 20px;"> Ενημέρωση Κατηγορίας</span>
        <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
</h1>

<div mat-dialog-content style="text-align: center; margin-top: 20px;">
    <form [formGroup]="form" style="width: 100%">
        <mat-form-field style="width: 100%"> 
            <mat-label>Ονομασία</mat-label>
            <input formControlName="value" style="width: 100%" [maxlength]="45" matInput [(ngModel)]="value"/>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Περιγραφή</mat-label>
            <textarea formControlName="details" style="width: 100%" matInput [(ngModel)]="details"></textarea>
        </mat-form-field>
    </form>

</div>
<div mat-dialog-actions align="end">
    <button style="margin-top: 10px;" [disabled]="!value" (click)="submit()" class="custom-btn" mat-raised-button>Αποθήκευση αλλαγών</button>
</div>