import {Injectable} from '@angular/core';
import {Transaction} from '../interfaces/transaction';
import {CommonService} from './common.service';
import {calculateThresholds} from '@angular-devkit/build-angular/src/angular-cli-files/utilities/bundle-calculator';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  // transactions: Transaction [] = [{
  //   id: 1,
  //   printOptions: [{
  //     categoryId: 1,
  //     categoryDescription: 'Χρώμα σελίδας',
  //     options: [{
  //       id: 1,
  //       description: 'Έγχρωμη',
  //       price: 0.25
  //     }]
  //   }, {
  //     categoryId: 2,
  //     categoryDescription: 'Βιβλιοδεσία',
  //     options: [{
  //       id: 1,
  //       description: 'Booklet',
  //       price: 0
  //     }],
  //   },
  //     {
  //       categoryId: 2,
  //       categoryDescription: 'Προσανατολισμός σελίδας',
  //       options: [{
  //         id: 1,
  //         description: 'Κάθετα',
  //         price: 0.10
  //       }]
  //     },
  //     {
  //       categoryId: 2,
  //       categoryDescription: 'Τύπος χαρτιού',
  //       options: [{
  //         id: 1,
  //         description: 'Α4',
  //         price: 0.10
  //       }]
  //     }, {
  //       categoryId: 2,
  //       categoryDescription: 'Μορφοποίηση σελίδας',
  //       options: [{
  //         id: 2,
  //         description: 'Οριζόντια',
  //         price: 0.10
  //       }]
  //     }, {
  //       categoryId: 2,
  //       categoryDescription: 'Αντίτυπα',
  //       options: [],
  //       price: 0.5,
  //       value: 2,
  //     }, {
  //       categoryId: 2,
  //       categoryDescription: 'Επιλογές αποστολής',
  //       options: [{
  //         id: 1,
  //         description: 'Παραλαβή από κατάστημα',
  //         price: 0
  //       }],
  //     }],
  //   date: '1 Απριλίου 2021',
  //   price: 5,
  //   state: 'Έτοιμο',
  //   userId: 1
  // },
  //   {
  //     id: 2,
  //     printOptions: [{
  //       categoryId: 1,
  //       categoryDescription: 'Χρώμα σελίδας',
  //       options: [{
  //         id: 1,
  //         description: 'Έγχρωμη',
  //         price: 0.25
  //       }]
  //     }, {
  //       categoryId: 2,
  //       categoryDescription: 'Βιβλιοδεσία',
  //       options: [{
  //         id: 1,
  //         description: 'Συρραφή',
  //         price: 0
  //       }],
  //     },
  //       {
  //         categoryId: 3,
  //         categoryDescription: 'Προσανατολισμός σελίδας',
  //         options: [{
  //           id: 1,
  //           description: 'Οριζόντια',
  //           price: 0.10
  //         }]
  //       },
  //       {
  //         categoryId: 4,
  //         categoryDescription: 'Τύπος χαρτιού',
  //         options: [{
  //           id: 1,
  //           description: 'Α4',
  //           price: 0.10
  //         }]
  //       }, {
  //         categoryId: 5,
  //         categoryDescription: 'Μορφοποίηση σελίδας',
  //         options: [{
  //           id: 2,
  //           description: 'Οριζόντια',
  //           price: 0.10
  //         }]
  //       }, {
  //         categoryId: 6,
  //         categoryDescription: 'Αντίτυπα',
  //         options: [],
  //         price: 0.5,
  //         value: 2,
  //       }, {
  //         categoryId: 7,
  //         categoryDescription: 'Επιλογές αποστολής',
  //         options: [{
  //           id: 1,
  //           description: 'Παραλαβή από κατάστημα',
  //           price: 0
  //         }],
  //       }],
  //     date: '3 Απριλίου 2021',
  //     price: 4,
  //     state: 'Έτοιμο',
  //     userId: 2
  //   }
  // ];


  constructor(private commonService: CommonService) {
  }

  async getTransactions() {
    try {
      const transactions = await this.commonService.getRequest(`request`);
      // const transactions = this.transactions;
      return transactions;
    } catch (e) {
      throw e;
    }
  }

  async getUserTransactions(user_id) {
    try {
      const transactions = await this.commonService.getRequest(`request?user_id=${user_id}`);
      // const transactions = this.transactions;
      return transactions;
    } catch (e) {
      throw e;
    }
  }

  async searchTransaction(item) {
    try {
      const search = await this.commonService.getRequest(`searchPrint?item=${item}`);
      return search;
    } catch (e) {
      throw e;
    }
  }

  async editRequest(item, mode) {
    try {
      await this.commonService.putRequest(`request?mode=${mode}`, item );
    } catch (e) {
      throw e;
    }
  }

  /**
   * Description: Returns the completed orders of user
   * @returns number
   */
   async getCompletedOrders() {
    try {
      let response = await this.commonService.getRequest('request?status_id=3')
      return response
    } catch (e) {
      throw e;
    }
  }

  /**
   * Description: Returns the pending orders of user
   * @returns number
   */
  async getPendingOrders() {
    try {
      let response = await this.commonService.getRequest('request?status_id=1')
      return response
    } catch (e) {
      throw e;
    }
  }

  async export(status) {
    try {
      return this.commonService.export(`request/export?status_id=${status}`)
    } catch (error) {
      throw error;
    }
  }
}
