<div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 100vh">
  <mat-spinner></mat-spinner>
</div>
<div fxHide.lt-md style="width: 100%;height: 90vh" *ngIf="dataLoaded">
  <mat-drawer-container>
    <mat-drawer mode="side" opened [ngStyle.lt-md]="{'width':'200px'}" class="mat-elevation-z2">
      <mat-selection-list [multiple]="false">
        <div class="profile-card">
          <h3>Καλωσήρθες {{user.username}}!</h3>
        </div>
        <mat-divider></mat-divider>
        <mat-list-option aria-selected="true" selected disableRipple (click)="showDetails(1)">
          <mat-icon matListIcon>person</mat-icon>Πληροφορίες</mat-list-option>
        <!-- <mat-list-option aria-selected="true" disableRipple (click)="showDetails(3)">
          <mat-icon matListIcon>settings</mat-icon>Ρυθμίσεις</mat-list-option> -->
        <mat-divider></mat-divider>
      </mat-selection-list>
    </mat-drawer>
    <mat-drawer-content>
      <div class="profile-content mat-elevation-z2">
        <app-profile-details [mode]="mode"></app-profile-details>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<div fxHide.gt-sm>

  <mat-tab-group (selectedTabChange)="changeTab($event)">
    <mat-tab label="Πληροφορίες">  </mat-tab>
  </mat-tab-group>
<!-- 
  <mat-toolbar color="primary" style="color: white; width: 100%;">
    <mat-toolbar-row fxLayoutAlign="start center">
      <button mat-button (click)="showDetails(1)">Πληροφορίες</button> -->
      <!-- <button mat-button (click)="showDetails(3)">Ρυθμίσεις</button> -->
    <!-- </mat-toolbar-row>
  </mat-toolbar> -->
  <div fxLayout="column" style="height: 100%; margin:2%;">
    <app-profile-details [mode]="mode"></app-profile-details>
  </div>
</div>
