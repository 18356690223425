
<div fxLayout="row" fxHide.lt-sm >
  <div fxLayout="column" fxFlex="35">
    <button mat-raised-button class="add-btn custom-btn" (click)="newCategoryModal()">
      Προσθήκη κατηγορίας
    </button>
  </div>
  <div fxLayout="column" fxFlex="65">
    <div *ngIf="+mode === 1" style="margin: 10px" fxLayout="row" fxLayoutAlign="start center center" class="mat-subheading-2">
      <mat-icon class="icon-config info-color" >info</mat-icon>Κατηγορίες που χρεώνονται ανά σελίδα
    </div>
    <div *ngIf="+mode === 0" style="margin: 10px"  fxLayout="row" fxLayoutAlign="start center center" class="mat-subheading-2">
      <mat-icon class="icon-config info-color">info</mat-icon>Κατηγορίες που χρεώνονται μία φορά ανά αρχείο</div>
  </div>
</div>


<div fxLayout="row">
  <div fxLayout="column">
    <div fxLayout="row wrap" fxHide.lt-sm fxLayoutAlign="start center center">
      <mat-card class="mat-elevation-z5 card-config"  [ngStyle.lt-lg]="{'width':'80%'}"*ngFor="let item of info">

        <div fxLayoutAlign="space-between center" *ngIf="item.edit === false" style="margin: 5px">
          <div class='header-c'>
            <span style="font-weight: bold">
              {{item.description}}
            </span>
            <div>
              <mat-icon *ngIf="item.details" [matTooltip]="item.details" class="details-config info-color">info</mat-icon>
            </div>
          </div>
          <button *ngIf="canDelete(item)" mat-icon-button (click)="deleteCategory(item)" matTooltip="Διαγραφή κατηγορίας">
            <mat-icon>delete</mat-icon>
          </button>
        </div>

        <div *ngIf="item.edit === true">
          <mat-form-field fxLayoutAlign="center">
            <input matInput [(ngModel)]="item.description"/>
          </mat-form-field>
        </div>
        <div fxLayout="column" *ngIf="item.properties.length > 0">

          <div fxLayoutAlign="center">Επιλογές</div><mat-divider [inset]="true"></mat-divider>
          <div class="properties-container">
            <mat-table [dataSource]="item.properties" style="width: 100%">
              <ng-container matColumnDef="description">
                <td style="width: 100%;" mat-cell *matCellDef="let element"> {{element.description}} </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <td mat-cell *matCellDef="let element" style="padding: 10px">
                  <mat-icon matTooltip="Ενεργή" style="cursor: initial;" *ngIf="element.is_active">visibility</mat-icon>
                  <mat-icon matTooltip="Ανενεργή" style="cursor: initial;" *ngIf="!element.is_active">visibility_off</mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <td style="padding-top: 10px;" mat-cell *matCellDef="let element" style="padding: 10px">
                  <mat-icon matTooltip="Επεξεργασία" (click)="editProperty(element)">edit</mat-icon>
                </td>
              </ng-container>
              <ng-container matColumnDef="delete">
                <td style="padding-top: 10px;" mat-cell *matCellDef="let element" style="padding: 10px">
                  <mat-icon matTooltip="Διαγραφή" (click)="deleteProperty(item, element)">delete</mat-icon>
                </td>
              </ng-container>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="width: 100%"></tr>
            </mat-table>
          </div>
        </div>

        <div fxLayout="column" *ngIf="item.properties.length <= 0">
          <div class="properties-container"></div>
        </div>

        <div>
          <div fxLayout="row" fxLayoutAlign="center center center" style=" margin-top: 20px">
            <div *ngIf="!item.edit" style="text-align: center;">
              <button style="width: 200px;" class="custom-btn" (click)="editCategoryDialog(item)" mat-raised-button>Επεξεργασία κατηγορίας</button>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="center center center" style="margin-top: 10px;" *ngIf="!item.edit">
            <div *ngIf="!item.edit"><button style="width: 200px;" class="custom-btn" (click)="addNewProperty(item.id)" mat-raised-button>Προσθήκη επιλογής</button></div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div fxLayout="row">
  <div class="small-content" fxHide.gt-sm >
      <div>
        <div *ngIf="+mode === 1" style="margin: 20px" fxLayout="row" fxLayoutAlign="center center center" class="mat-subheading-2">
          <mat-icon class="icon-config info-color">info</mat-icon>Κατηγορίες που χρεώνονται ανά σελίδα
        </div>
        <div *ngIf="+mode === 0" style="margin: 20px; width: 100%;"  fxLayout="row" fxLayoutAlign="center center center" class="mat-subheading-2">
          <mat-icon class="icon-config info-color">info</mat-icon>Κατηγορίες που χρεώνονται μία φορά ανά αρχείο</div>
      </div>

      <div>
        <button mat-raised-button class="add-btn custom-btn" (click)="newCategoryModal()">
          Προσθήκη κατηγορίας
        </button>
      </div>

    <mat-card class="mat-elevation-z5 card-config" style="width:80%" *ngFor="let item of info">

      <div fxLayoutAlign="space-between center" *ngIf="item.edit === false" style="margin: 5px">
        <div class='header-c'>
          <span style="font-weight: bold">
            {{item.description}}
          </span>
          <div>
            <mat-icon *ngIf="item.details" [matTooltip]="item.details" class="details-config info-color">info</mat-icon>
          </div>
        </div>
        <button *ngIf="canDelete(item)" mat-icon-button (click)="deleteCategory(item)" matTooltip="Διαγραφή κατηγορίας">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div *ngIf="item.edit === true">
        <mat-form-field fxLayoutAlign="center">
          <input matInput [(ngModel)]="item.description"/>
        </mat-form-field>
      </div>
      <div fxLayout="column" *ngIf="item.properties.length > 0">

        <div fxLayoutAlign="center">Επιλογές</div><mat-divider [inset]="true"></mat-divider>
        <div class="properties-container">
          <mat-table [dataSource]="item.properties" style="width: 100%">
            <ng-container matColumnDef="description">
              <td style="width: 100%;" mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <td mat-cell *matCellDef="let element">
                <mat-icon matTooltip="Ενεργή" class="validBadge" style="cursor: initial;" *ngIf="element.is_active">check</mat-icon>
                <mat-icon matTooltip="Ανενεργή" class="invalidBadge" style="cursor: initial;" *ngIf="!element.is_active">close</mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <td style="padding-top: 10px;" mat-cell *matCellDef="let element">
                <mat-icon matTooltip="Επεξεργασία" (click)="editProperty(element)">edit</mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="delete">
              <td style="padding-top: 10px;" mat-cell *matCellDef="let element">
                <mat-icon matTooltip="Διαγραφή" (click)="deleteProperty(item, element)">delete</mat-icon>
              </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="width: 100%"></tr>
          </mat-table>
        </div>
      </div>

      <div fxLayout="column" *ngIf="item.properties.length <= 0">
        <div class="properties-container"></div>
      </div>

      <div>
        <div fxLayout="row" fxLayoutAlign="center center center" style=" margin-top: 20px">
          <div *ngIf="!item.edit" style="text-align: center;">
            <button style="width: 200px;" class="custom-btn" (click)="editCategoryDialog(item)" mat-raised-button>Επεξεργασία κατηγορίας</button>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center center" style="margin-top: 10px;" *ngIf="!item.edit">
          <div *ngIf="!item.edit"><button style="width: 200px;" class="custom-btn" (click)="addNewProperty(item.id)" mat-raised-button>Προσθήκη επιλογής</button></div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
<!--<div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 100%"><mat-spinner></mat-spinner></div>-->
