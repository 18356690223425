import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import * as FileSaver from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class CommonService {



  constructor(public httpClient: HttpClient, private router: Router) { }

  getRequest(endpoint: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${endpoint}`).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  postRequest(endpoint, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${endpoint}`, model).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  putRequest(endpoint, model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(`${endpoint}`, model).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  deleteRequest(endpoint): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(`${endpoint}`).subscribe(data => {
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public downloadFile(id, name) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`request/file/${id}`, {
        responseType: 'blob' // This must be a Blob type
      }).subscribe(res => {
        FileSaver.saveAs((res as Blob), name, 'application/pdf');
      }, error => reject({message: 'Το αρχείο δε βρέθηκε.'}));
    });
  }

  public export(endpoint) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(endpoint, {
        responseType: 'blob' // This must be a Blob type
      }).subscribe(res => {
        const myJsonString = JSON.stringify(res);
        const blob = new Blob([myJsonString], {
          type: "application/vnd.ms-excel;charset=utf-8"
        });
        FileSaver.saveAs(blob);
      }, error => reject({message: 'Το αρχείο δε βρέθηκε.'}));
    });
  }
}
