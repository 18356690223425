import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';

import { ChartType, ChartOptions } from 'chart.js';
import * as chart from 'ng2-charts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  month_complete;
  month_pending;
  month_cost = 0;

  today_complete;
  today_pending;
  today_cost = 0;

  dataLoaded = false;

  monthLabels = ['Oλοκληρωμένες', 'Σε αναμονή'];
  todayLabels = ['Oλοκληρωμένες', 'Σε αναμονή'];
  totalLabels = ['Oλοκληρωμένες', 'Σε αναμονή'];

  today_data;
  total_data;
  total_cost;

  total_pending = 0;
  total_completed = 0;

  total_month_data = [];
  total_month_labels;

  yearCost = 0;

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: false
  };

  public barChartLegend = true;

  display_year = new Date().getFullYear();
  availableYears = [];
  allMonthlyData = [];

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  month_data;
  month_ready = true;

  constructor(private commonService: CommonService) {

  }

  async ngOnInit(): Promise<void> {
    await this.syncData();
  }

  async syncData() {

    try {
      const response = await this.commonService.getRequest('request/totals');

      this.month_complete = response.monthly_completed;
      this.month_pending = response.monthly_pending;

      this.month_cost = response.monthly_cost;
      this.total_cost = response.total_cost;
      this.today_complete = response.today_completed;
      this.today_pending = response.today_pending;

      this.month_data =  [
        { name: 'Oλοκληρωμένες', value: response.monthly_completed },
        { name: 'Σε αναμονή', value: response.monthly_pending }
      ];

      this.today_data = [
        { name: 'Oλοκληρωμένες', value: this.today_complete },
        { name: 'Σε αναμονή', value: this.today_pending }
      ];

      const pending_count = await this.commonService.getRequest('request/count?status_id=1');

      this.total_pending = pending_count.count;
      const complete_count = await this.commonService.getRequest('request/count?status_id=3');
      this.total_completed = complete_count.count;

      this.total_data = [
        { name: 'Oλοκληρωμένες', value: this.total_completed },
        { name: 'Σε αναμονή', value: this.total_pending }
      ];

      this.allMonthlyData = response.monthly_yearly_cost;

      this.total_month_data = [];

      const now_year = new Date().getFullYear();

      for (let i = 1; i <= 12; i++) {

        this.allMonthlyData.forEach(element => {

          if (!this.availableYears.includes(element.year)) {
            this.availableYears.push(element.year);
          }

          if (now_year === element.year) {

            if (element.month === i) {
              this.total_month_data.push({
                name: this.getMonth(element.month),
                value: element.sum
              });
              this.yearCost += parseFloat(element.sum);
            } else {
              this.total_month_data.push({
                name: this.getMonth(i),
                value: 0
              });
            }
          }

        });

      }

      this.display_year = this.availableYears[this.availableYears.length - 1];
      this.changeYear({value: this.display_year});
      this.dataLoaded = true;

    } catch (error) {

    }

  }

  changeYear(event) {
    this.month_ready = false;
    const year = event.value;

    this.total_month_data = [];
    this.yearCost = 0;

    const data = [];

    for (let i = 1; i <= 12; i++) {

      this.allMonthlyData.forEach(element => {

        if (!this.availableYears.includes(element.year)) {
          this.availableYears.push(element.year);
        }

        if (year === element.year) {
          if (element.month === i) {
            this.total_month_data.push({
              name: this.getMonth(element.month),
              value: element.sum
            });
            this.yearCost += parseFloat(element.sum);
          } else {
            this.total_month_data.push({
              name: this.getMonth(i),
              value: 0
            });
          }
        }
      });

      this.month_ready = true;

    }
  }

   randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));

   getMonth(month) {
     if (month === 1) {
       return 'Ιανουάριος';
     }
     if (month === 2) {
      return 'Φεβρουάριος';
    }
     if (month === 3) {
      return 'Μάρτιος';
    }
     if (month === 4) {
      return 'Απρίλιος';
    }
     if (month === 5) {
      return 'Μάιος';
    }
     if (month === 6) {
      return 'Ιούνιος';
    }
     if (month === 7) {
      return 'Ιούλιος';
    }
     if (month === 8) {
      return 'Αύγουστος';
    }
     if (month === 9) {
      return 'Σεπτέμβριος';
    }
     if (month === 10) {
      return 'Οκτώβριος';
    }
     if (month === 11) {
      return 'Νοέμβριος';
    }
     if (month === 12) {
      return 'Δεκέμβριος';
    }
   }
}
