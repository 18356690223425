<div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="mat-subheading-1" fxLayoutAlign="center center center">Δημιουργία τρόπου αποστολής </div>
      <mat-icon style="cursor: pointer; margin-bottom: 10px;" (click)="closeDialog()">clear</mat-icon>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label>Ονομασία</mat-label>
        <input matInput [(ngModel)]="description"/>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Τιμή</mat-label>
        <input matInput [(ngModel)]="price"/>
      </mat-form-field>
      <button (click)="save()" mat-raised-button color="primary">Αποθήκευση</button>
    </div>
  </div>
  