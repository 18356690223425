import {Injectable} from '@angular/core';
import {CommonService} from './common.service';
import {Router} from '@angular/router';
import {ToastMessagesService} from './toast-messages.service';
import { ProgressSpinnerDialogService } from './progress-spinner-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private progressSpinnerDialogService: ProgressSpinnerDialogService, private commonService: CommonService, private router: Router, public toastMessagesService: ToastMessagesService) {
  }

  async getMyInfo(expire = true) {
    try {
      const user = await this.commonService.getRequest('user/me');

      if (user !== undefined) {
        if (user.id) {
          localStorage.setItem('user', JSON.stringify(user));
          return user;
        }
        return false;
      } else {
        return false;
      }

    } catch (e) {

      if (e.status === 401 && expire) {
        this.adminLogout(true)
      }

      // console.log(e);
    }
  }
  async adminLogin(name, password) {
    try {
      const admin = {
        username: name,
        password,
        admin_request: 1
      };
      const result = await this.commonService.postRequest(`login`, admin);
      localStorage.setItem('token', result);
      await this.getMyInfo();
      this.progressSpinnerDialogService.end();

      await this.router.navigateByUrl('home/dashboard');
    } catch (e) {
      console.log(e);
      this.toastMessagesService.toastErrorMessage(e.error.message);
    }
  }

  adminLogout(expired = false) {
    try {
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      if (expired) {
        this.toastMessagesService.toastErrorMessage('Έληξε η περίοδος της σύνδεσής σας, παρακαλώ συνδεθείτε ξανά.')
      }
      this.router.navigate(['/login']);
    } catch (e) {
      throw e;
    }
  }

  async updateMyInfo(user) {
    try {
      const userUpdated = await this.commonService.putRequest(`user`, user);
      // localStorage.setItem('user', JSON.stringify(user));
      return userUpdated;
    } catch (e) {
      throw e;
    }
  }
}
