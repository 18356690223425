import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastMessagesService } from '../../../services/toast-messages.service';
import { Component, OnInit, Inject } from '@angular/core';
import {UserService} from '../../../services/user.service';



@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {

  title: string;
  message: string;
  object: {} ;


  constructor(
    private dialog: MatDialog, private userService: UserService,
    private toastMessagesService: ToastMessagesService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.title = this.data.title;
    this.message = this.data.message;
    this.object = this.data.object;
  }

  ngOnInit() {

  }

  async onConfirm() {
    // Close the dialog, return true
    this.dialogRef.close(true);
    await this.userService.changeRole(this.object);
    this.toastMessagesService.toastSuccessMessage('Η επιλογή αποθηκεύτηκε επιτυχώς.');
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
