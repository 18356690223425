<h1 mat-dialog-title style="width: 60vw;">
    <div class="header">
        <span style="font-size: larger; margin-right: 20px;"> {{getTitle()}}</span>
        <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </div>
  </h1>
  <div fxLayout="column">
    <form [formGroup]="form" class="form" style="width: 100%">

        <mat-form-field style="width: 50%;">
            <input formControlName="title" matInput placeholder="Τίτλος" type="text"/>
            <mat-error *ngIf="form.get('title')?.invalid">
              Παρακαλώ πληκτρολογήστε τίτλο
            </mat-error>
        </mat-form-field>
        <angular-editor formControlName="context" [config]="editorConfig" placeholder="Περιεχόμενο"></angular-editor>
    </form>
  </div>

  <div mat-dialog-actions align="end">
    <button (click)="submit()" [disabled]="form.invalid" mat-raised-button class="custom-btn">Αποθήκευση αλλαγών</button>
  </div>