import { CommonService } from './../../services/common.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-details',
  templateUrl: './settings-details.component.html',
  styleUrls: ['./settings-details.component.scss']
})
export class SettingsDetailsComponent implements OnInit {

  @Input() mode;
  dataLoaded = true;

  shippings = [];

  constructor(private commonService: CommonService) { }

  async ngOnInit(): Promise<void> {
     try {

     } catch (error) {

     }
  }


  async loadShippings() {
    try {
      const response = await this.commonService.getRequest('shipping');

      this.shippings = response;
    } catch (error) {

    }
  }

}
