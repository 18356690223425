<mat-toolbar class="mat-toolbar" style="height: 50px">
  <mat-toolbar-row fxLayoutAlign="space-between center">
    <div style="cursor: default;"><app-header></app-header></div>
    <div class="menu" fxHide.xs>
      <button mat-button class="nav-btn" [routerLink]="['/home/dashboard']" routerLinkActive="active"><mat-icon>home</mat-icon> Αρχική σελίδα
      </button>
      <button mat-button class="nav-btn" [routerLink]="['/home/announcements']" routerLinkActive="active"><mat-icon>announcement</mat-icon> Ανακοινώσεις
      </button>
      <button mat-button class="nav-btn" [routerLink]="['/home/main']" routerLinkActive="active"><mat-icon style="padding-right: 5px;">print</mat-icon>Εκτυπώσεις
      </button>
      <button mat-button class="nav-btn" [routerLink]="['/home/settings']" routerLinkActive="active" ><mat-icon style="padding-right: 5px;">settings</mat-icon>Ρυθμίσεις</button>
      <button mat-button class="nav-btn" [routerLink]="['/home/users']" routerLinkActive="active"><mat-icon style="padding-right: 5px;">supervisor_account</mat-icon>Χρήστες
      </button>
<!--      <button mat-button [routerLink]="['/home/profile']" routerLinkActive="active">Προφίλ <mat-icon>person</mat-icon></button>-->
      <button mat-flat-button class="nav-btn" [matMenuTriggerFor]="beforeMenu" (click)="myInfo()">{{getFullname()}}<mat-icon>keyboard_arrow_down</mat-icon></button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">

        <button mat-menu-item [routerLink]="['/home/profile']"><mat-icon>vpn_key</mat-icon>Ο Λογαριασμός μου</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="adminLogout()">
          <mat-icon>exit_to_app</mat-icon> Αποσύνδεση
        </button>
      </mat-menu>
    </div>
    <div fxHide.gt-xs>
      <button style="color: white;" mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-menu #menu="matMenu" fxLayoutAlign="end">
  <span (mouseleave)="menuTrigger.closeMenu()">
    <a routerLinkActive="active" routerLink="/home/main"> <button fxLayoutAlign="center" mat-menu-item>Αρχική Σελίδα</button></a>
    <mat-divider></mat-divider>
    <a routerLinkActive="active" routerLink="/home/main"><button fxLayoutAlign="center" mat-menu-item>Εκτυπώσεις</button></a>
    <mat-divider></mat-divider>
    <a routerLinkActive="active" routerLink="/home/edit-category"><button fxLayoutAlign="center" mat-menu-item>Επιλογές Εκτύπωσης</button></a>
    <mat-divider></mat-divider>
    <a routerLinkActive="active" routerLink="/home/users"><button fxLayoutAlign="center" mat-menu-item>Χρήστες</button></a>
    <mat-divider></mat-divider>
    <a routerLinkActive="active" routerLink="/home/profile"><button fxLayoutAlign="center" mat-menu-item>Προφίλ</button></a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="adminLogout()" fxLayoutAlign="center">Αποσύνδεση</button>
  </span>
</mat-menu>
<div style="height:80%;">
  <router-outlet></router-outlet>
</div>
