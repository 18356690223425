<div fxHide.lt-md class="graphs-container" *ngIf="dataLoaded">
    <div class="graph">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Σημερινές Εκτυπώσεις
                 </mat-card-title>
            </mat-card-header>

             <ngx-charts-pie-chart 
                *ngIf="today_completed > 0 || today_pending > 0"
                [results]="today_data"
                [view]="[500,400]"
                [labels]="true" >
            </ngx-charts-pie-chart>
             <div style="width: 25vw" *ngIf="today_completed <= 0 || today_pending <= 0">
                Δεν υπάρχουν σημερινές εκτυπώσεις.
            </div>
        </mat-card>
    </div>
    <div class="graph">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Μηνιαίες Εκτυπώσεις
                </mat-card-title>
            </mat-card-header>
             <ng-container *ngIf="month_complete > 0 || month_pending > 0">
                <ngx-charts-pie-chart 
                    [results]="month_data"
                    [view]="[500,400]"
                    [labels]="true" >
                </ngx-charts-pie-chart>
                <div class="total-div">
                    <div class="total-text">
                        Μηνιαία έσοδα:
                    </div>
                    <div class="total-text">
                        {{month_cost}}€
                    </div>
                </div>
             </ng-container>
            <div style="width: 25vw" *ngIf="month_complete <= 0 || month_pending <= 0">
                Δεν υπάρχουν εκτυπώσεις αυτό το μήνα.
            </div>
        </mat-card>
    </div>
    <div class="graph">
        <mat-card *ngIf="month_data && monthLabels">
            <mat-card-header>
                <mat-card-title>
                    Συνολικές Εκτυπώσεις
                </mat-card-title>
            </mat-card-header>
            <ngx-charts-pie-chart 
                [results]="total_data"
                [view]="[500,400]"
                [labels]="true" >
            </ngx-charts-pie-chart>
            <div class="total-div">
                <div class="total-text">
                    Συνολικά έσοδα:
                </div>
                <div class="total-text">
                    {{total_cost}}€
                </div>
            </div>
        </mat-card>
    </div>

    <div class="months-graph">
        <mat-card style="width: 90vw; height: 500px;"> 
            <mat-card-title>
                <div fxLayout="row">
                    <div>
                        Έσοδα ανά μήνα
                    </div>
                    <div fxLayoutAlign="center center center" style="padding-left: 20px; width: 100px;">
                        <mat-select (selectionChange)="changeYear($event)" ngDefaultControl [(ngModel)]="display_year">
                            <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </mat-card-title>

          <div style="width: 90vw; height: 420px;">
            <ngx-charts-bar-vertical
            [results]="total_month_data"
            [labels]="true" 
            [xAxis]="true"
            [yAxis]="true"
        >
        </ngx-charts-bar-vertical> 
          </div>
 
           

    

            <div class="total-div" >
                <div class="total-text" style="margin-top: 20px;">
                    Συνολικά έσοδα:
                </div>
                <div class="total-text" style="margin-top: 20px;">
                    {{yearCost.toFixed(2)}}€
                </div>
            </div>
 
        </mat-card>
    </div>
</div>

<div fxHide.gt-sm class="graphs-container" *ngIf="dataLoaded">
    <div class="graph">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Σημερινές Εκτυπώσεις
                 </mat-card-title>
            </mat-card-header>

             <ngx-charts-pie-chart 
                *ngIf="today_completed > 0 || today_pending > 0"
                [results]="today_data"
                [view]="[300, 200]"
                [labels]="true" >
            </ngx-charts-pie-chart>
             <div style="width: 25vw" *ngIf="today_completed <= 0 || today_pending <= 0">
                Δεν υπάρχουν σημερινές εκτυπώσεις.
            </div>
        </mat-card>
    </div>
    <div class="graph">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    Μηνιαίες Εκτυπώσεις
                </mat-card-title>
            </mat-card-header>
             <ng-container *ngIf="month_complete > 0 || month_pending > 0">
                <ngx-charts-pie-chart 
                    [results]="month_data"
                    [view]="[300,200]"
                    [labels]="true" >
                </ngx-charts-pie-chart>
                <div class="total-div">
                    <div class="total-text">
                        Μηνιαία έσοδα:
                    </div>
                    <div class="total-text">
                        {{month_cost}}€
                    </div>
                </div>
             </ng-container>
            <div style="width: 25vw" *ngIf="month_complete <= 0 || month_pending <= 0">
                Δεν υπάρχουν εκτυπώσεις αυτό το μήνα.
            </div>
        </mat-card>
    </div>
    <div class="graph">
        <mat-card *ngIf="month_data && monthLabels">
            <mat-card-header>
                <mat-card-title>
                    Συνολικές Εκτυπώσεις
                </mat-card-title>
            </mat-card-header>
            <ngx-charts-pie-chart 
                [results]="total_data"
                [view]="[300,200]"
                [labels]="true" >
            </ngx-charts-pie-chart>
            <div class="total-div">
                <div class="total-text">
                    Συνολικά έσοδα:
                </div>
                <div class="total-text">
                    {{total_cost}}€
                </div>
            </div>
        </mat-card>
    </div>

    <div class="months-graph">
        <mat-card>
            <mat-card-title>
                <div fxLayout="row">
                    <div>
                        Έσοδα ανά μήνα
                    </div>
                    <div fxLayoutAlign="center center center" style="padding-left: 20px; width: 100px;">
                        <mat-select (selectionChange)="changeYear($event)" ngDefaultControl [(ngModel)]="display_year">
                            <mat-option *ngFor="let year of availableYears" [value]="year">{{year}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                
            </mat-card-title>
                <ngx-charts-bar-horizontal
                [results]="total_month_data"
                [labels]="true" 
                [yAxis]="true"
                [view]="[300,400]"
                legendPosition="below"
            >
            </ngx-charts-bar-horizontal>
            <div class="total-div" style="margin-top: 20px;">
                <div class="total-text">
                    Συνολικά έσοδα:
                </div>
                <div class="total-text">
                    {{yearCost.toFixed(2)}}€
                </div>
            </div>
 
        </mat-card>
    </div>
</div>