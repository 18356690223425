import {FormBuilder, FormGroup, Validators, FormArray, ValidatorFn, ValidationErrors} from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { EditService } from '../../services/edit.service';


@Component({
  selector: 'app-add-property-dialog',
  templateUrl: './add-property-dialog.component.html',
  styleUrls: ['./add-property-dialog.component.scss']
})
export class AddPropertyDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddPropertyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private editService: EditService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private formBuilder: FormBuilder) {
    this.data = data;
  }

  get scaleFields() {
    return this.form.get('scales') as FormArray;
  }
  // id: number
  // name: string
  // price: string
  // isChecked: any = true
  // has_scale: boolean = false
  // from: number
  // to: number
  // scale_price: number
  data: any;
  propObj: any;
  form: FormGroup;
  myForm: FormGroup;
  mode = true;
  success = false;
  initValue = 0;
  index = 0;
  validScale = false;
  maxIndex = -1;
  deletable = [true];


  ngOnInit(): void {
    this.mode = !this.data.id;
    this.form = this.formBuilder.group({
      description: ['', Validators.required],
      price: ['', Validators.required],
      is_active: [false],
      has_scale: [false],
      scales: this.formBuilder.array([]),
    });

    if (!this.mode){
      this.data.scales?.forEach(e => this.addScale());
      this.form.patchValue(this.data);
    }
    // optional: form stepper: if has_scale enable step 2
    // from valid&touched && to valid&touched &&  price valid&touched -> add new fields
    //
    // errors:
    // from < to
    //  to > from
    // from2 = from1 + 1
    // last to?

    // save_button : [disabled]="form1Valid&form2Valid"
    // this.scaleForm = this.formBuilder.group({
    //   from:[
    //     '',
    //     Validators.required
    //   ],
    //   to:[
    //     '',
    //     Validators.required
    //   ],
    //   scale_price:[
    //     '',
    //     Validators.required
    //   ]
    // })
  }

  public comparisonValidator(): ValidatorFn{
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls.from;
      const control2 = group.controls.to;
      if (control1.value >= control2.value) {
        control2.setErrors({greaterThan: true});
      } else {
        control2.setErrors(null);
      }
      return;
    };
  }

  public scaleCheck(i){
    if ((i === 0 && this.form.value.scales[i].from < 0) ||
      (i > 0 && this.form.value.scales[i].from !== this.form.value.scales[i - 1].to + 1)){
      this.myForm.reset();
      this.validScale = false;
    }
    else {
      this.validScale = true;
      if (i >= this.maxIndex){
        this.maxIndex = i;
      }
    }
    this.initValue = this.form.value.scales[i].to + 1;
    if (i === this.maxIndex) {
      this.deletable[i] = true;
      this.deletable[i - 1] = false;
      this.deletable[i + 1] = true;
    }
  }

  increment(){
    this.maxIndex++;
  }

  addScale(){
    this.myForm = this.formBuilder.group({
      from: [this.initValue, Validators.required],
      to: [, Validators.required],
      price: [, Validators.required]
    });
    this.myForm.setValidators(this.comparisonValidator());
    const scale = this.myForm;
    this.scaleFields.push(scale);
    this.initValue = this.data.scales[this.index].to + 1;
    this.maxIndex = this.index;
    this.index++;
  }

  // Old Version
  // addScale() {
  //   const scale = this.formBuilder.group({
  //     from: [, Validators.required],
  //     to: [, Validators.required],
  //     price: [, Validators.required]
  //   })
  //   this.scaleFields.push(scale);
  // }

  deleteScale(i) {
    if (i === this.maxIndex && i > 0){
      this.scaleFields.removeAt(i);
      this.maxIndex--;
      this.initValue = this.form.value.scales[i - 1].to + 1;
      this.deletable[this.maxIndex] = true;
    }
    if (i === this.maxIndex && i === 0){
      this.scaleFields.removeAt(i);
      this.maxIndex = -1;
      this.initValue = 0;
    }
    if (i === 0){
      this.index = 0;
    }
  }

  deleteAll(){
    let i = this.scaleFields.length;
    while (this.scaleFields.length > 0){
      this.scaleFields.removeAt(i);
      i--;
    }
    this.maxIndex = -1;
    this.initValue = 0;
  }

  // isDisabled() {
  //   return (this.name.length === 0 || this.price.length === 0 );
  // }

  async addProperty() {
    this.progressSpinnerDialogService.show();
    const formValue = this.form.value;
    this.propObj = formValue;
    this.propObj.has_scale = formValue.scales.length !== 0;
    this.propObj.price = formValue.has_scale ? 0 : formValue.price;
    try {
      if (this.mode){
        this.propObj.category_id = this.data;
        await this.editService.addNewProperty(this.propObj);
      }
      else
      {
        this.propObj.category_id = this.data.category_id;
        this.propObj.id = this.data.id;
        await this.editService.editProperty(this.propObj);
      }
      this.success = true;
    } catch (e) {
      console.log(e);
    }
    this.progressSpinnerDialogService.end();
    // try {
    //   let scaleObj={}
    //   if (this.has_scale){
    //     scaleObj = {
    //       from: this.from,
    //       to: this.to,
    //       price: this.scale_price
    //     }}
    //   const model = {
    //     // id: null,
    //     description: this.name,
    //     price: parseFloat(this.price).toFixed(2),
    //     is_active: this.isChecked,
    //     has_scale: this.has_scale,
    //     scales: scaleObj,
    //     category_id: this.id
    //   };
    //   this.progressSpinnerDialogService.show();
    //   console.log(model)
    //   // await this.editService.addNewProperty(model);
    //   this.dialogRef.close(model);
    // } catch (e) {
    //   this.progressSpinnerDialogService.end();
    //   console.log(e);
    // }
  }

  closeDialog() {
    this.dialogRef.close(this.propObj);
  }

  // checkInput(event) {
  //   const keyCode = event.keyCode;

  //   const excludedKeys = [8, 37, 39, 46, 190];

  //   if (!((keyCode >= 48 && keyCode <= 57) ||
  //     (keyCode >= 96 && keyCode <= 105) ||
  //     (excludedKeys.includes(keyCode)))) {
  //     event.preventDefault();
  //   }
  // }

  // getValue() {
  //   if (this.isChecked === false) {
  //     this.isChecked = 0;
  //   } else {
  //     this.isChecked = 1;
  //   }
  // }
}
