import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';

@Component({
  selector: 'app-new-shippings-dialog',
  templateUrl: './new-shippings-dialog.component.html',
  styleUrls: ['./new-shippings-dialog.component.scss']
})
export class NewShippingsDialogComponent implements OnInit {

  description = '';
  price = '';

  constructor(private dialogRef: MatDialogRef<NewShippingsDialogComponent>,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private commonService: CommonService) { }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async save() {

  }
}
