<div *ngIf="dataLoaded">
<mat-card style="width: 250px;border-radius: 20px">
  <div fxLayoutAlign="center" style="margin:10px">Στοιχεία χρήστη</div>
  <div>Ονοματεπώνυμο: {{info.first_name}} {{info.last_name}}</div>
 
  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <button mat-icon-button  (click)="getUserPrintOrders(info.id)"><mat-icon>print</mat-icon></button>
  </div>
  <mat-card-content>
    <div fxLayout="row">
      Όνομα χρήστη: {{info.username}}
    </div>
    <div fxLayout="row">
      Email: {{info.email}}
    </div>
    <div fxLayout="row">
      Τηλέφωνο: {{info.phone_number}}
    </div>
    <div fxLayout="row">
      Διεύθυνση: {{info.address}}, {{info.postal_code}}
    </div>
  </mat-card-content>
</mat-card>
</div>
