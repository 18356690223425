import { ToastMessagesService } from './../../services/toast-messages.service';
import { CommonService } from './../../services/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import {FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-shippings-dialog',
  templateUrl: './edit-shippings-dialog.component.html',
  styleUrls: ['./edit-shippings-dialog.component.scss']
})
export class EditShippingsDialogComponent implements OnInit {

  form: FormGroup
  myForm: FormGroup;
  initValue = 0;
  validScale = false;
  maxIndex = -1;
  deletable = [true];
  index = 0;
  mode = true;

  get scaleFields() {
    return this.form.get('scales') as FormArray;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<EditShippingsDialogComponent>,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              public formBuilder: FormBuilder,
              private commonService: CommonService,
              private toastMessagesService: ToastMessagesService) {
  }

  ngOnInit(): void {
    this.mode = !this.data.id;
    this.form = this.formBuilder.group({
      description: [this.data.description, Validators.required],
      price: [this.data.price, Validators.required],
      is_active: [this.data.is_active, Validators.required],
      has_scale: [false],
      scales: this.formBuilder.array([]),
    })
    if (!this.mode){
      this.data.scales?.forEach(() => this.addScale());
      this.form.patchValue(this.data);
    }
  }

  public comparisonValidator(): ValidatorFn{
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls.from;
      const control2 = group.controls.to;
      if (control1.value >= control2.value) {
        control2.setErrors({greaterThan: true});
      } else {
        control2.setErrors(null);
      }
      return;
    };
  }


  public scaleCheck(i){
    if ((i === 0 && this.form.value.scales[i].from < 0) ||
      (i > 0 && this.form.value.scales[i].from !== this.form.value.scales[i - 1].to + 1)){
      this.myForm.reset();
      this.validScale = false;
    }
    else {
      this.validScale = true;
      if (i >= this.maxIndex){
        this.maxIndex = i;
      }
    }
    this.initValue = this.form.value.scales[i].to + 1;
    if (i === this.maxIndex) {
      this.deletable[i] = true;
      this.deletable[i - 1] = false;
      this.deletable[i + 1] = true;
    }
  }

  increment(){
    this.maxIndex++;
  }

  addScale(){
    this.myForm = this.formBuilder.group({
      from: [this.initValue, Validators.required],
      to: [undefined, Validators.required],
      price: [undefined, Validators.required]
    });
    this.myForm.setValidators(this.comparisonValidator());
    const scale = this.myForm;
    this.scaleFields.push(scale);
    this.initValue = this.data.scales[this.index].to + 1;
    this.maxIndex = this.index;
    this.index++;
    this.deletable[this.index] = true;
    for (let j = 0; j < this.index - 1; j++){
      this.deletable[j] = false;
    }
  }

  deleteScale(i) {
    if (i === this.maxIndex && i > 0){
      this.scaleFields.removeAt(i);
      this.maxIndex--;
      this.initValue = this.form.value.scales[i - 1].to + 1;
      this.deletable[this.maxIndex] = true;
    }
    if (i === this.maxIndex && i === 0){
      this.scaleFields.removeAt(i);
      this.maxIndex = -1;
      this.initValue = 0;
    }
    if (i === 0){
      this.index = 0;
    }
  }

  deleteAll(){
    let i = this.scaleFields.length;
    while (this.scaleFields.length > 0){
      this.scaleFields.removeAt(i);
      i--;
    }
    this.maxIndex = -1;
    this.initValue = 0;
  }

  async editShipping() {
    try {
      this.progressSpinnerDialogService.show();
      if(!this.form.value.has_scale){
        delete this.form.value.scales
      }
      const body = {
        id: this.data.id,
        ...this.form.value
      };
      if (this.data.is_new) {
        await this.commonService.postRequest('shipping', body);
      } else {
        await this.commonService.putRequest('shipping', body);
      }

      this.toastMessagesService.toastSuccessMessage('Ο τρόπος αποστολής αποθηκεύτηκε επιτυχώς');
      this.dialogRef.close(body);
      this.progressSpinnerDialogService.end();

    } catch (e) {
      console.log(e);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getTitle() {
    if (this.data.is_new) {
      return 'Προσθήκη Τρόπου Αποστολής';
    }

    return 'Ενημέρωση Τρόπου Αποστολής';
  }
}
