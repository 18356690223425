import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastMessagesService} from '../../services/toast-messages.service';
import {CommonService} from '../../services/common.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-options-dialog',
  templateUrl: './options-dialog.component.html',
  styleUrls: ['./options-dialog.component.scss']
})
export class OptionsDialogComponent implements OnInit {
  info: any;
  displayColumns = ['Α/Α', 'Αρχείο', 'Σελίδες', 'Αντίτυπα', 'Προσανατολισμός σελίδας', 'Μορφοποίηση σελίδας'];
  address;

  constructor(public dialogRef: MatDialogRef<OptionsDialogComponent>, private commonService: CommonService,
              public router: Router, public toastMessagesService: ToastMessagesService,
              @Inject(MAT_DIALOG_DATA) data
              ) {
    this.info = data;
    this.info.options.forEach(element => {
      if (element.address) {
        this.address = element;
      }
    });

  }

  async ngOnInit() {
    this.info.options[0].properties.forEach(element => {
      this.displayColumns.push(element.category_description);
    });

    // console.log(this.router.url);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async downloadFile(id: number, name: string) {
    try {
      await this.commonService.downloadFile(id, name);
    } catch (e) {
      console.log(e);
      this.toastMessagesService.toastErrorMessage(e.message);
    }
  }

}
