import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';

@Component({
  selector: 'app-announcement-dialog',
  templateUrl: './announcement-dialog.component.html',
  styleUrls: ['./announcement-dialog.component.scss']
})
export class AnnouncementDialogComponent implements OnInit {
  announcement;

  editorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',

    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  constructor(@Inject(MAT_DIALOG_DATA) data,
              private dialogRef: MatDialogRef<AnnouncementDialogComponent>,
              private commonService: CommonService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private toastMessagesService: ToastMessagesService,
              ) {
    this.announcement = data;
  }

  form: FormGroup;

  ngOnInit(): void {

    this.form = new FormGroup({
      title: new FormControl(this.announcement?.title, Validators.required),
      context: new FormControl(this.announcement?.context, Validators.required),
    });
  }

  async submit() {
    try {
      this.progressSpinnerDialogService.show();
      const body = this.form.value;

      if (this.announcement.is_new) {
        await this.commonService.postRequest('announcement', body);
      } else {
        await this.commonService.putRequest(`announcement/${this.announcement.id}`, body);
      }

      this.toastMessagesService.toastSuccessMessage('Η ανακοίνωση αποθηκεύτηκε επιτυχώς');

      this.dialogRef.close(this.announcement);
      this.progressSpinnerDialogService.end();

      this.dialogRef.afterClosed().subscribe( result => {
        if (result) {
          return result;
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getTitle() {
    if (this.announcement.is_new) {
      return 'Προσθήκη Ανακοίνωσης';
    }

    return 'Ενημέρωση Ανακοίνωσης';
  }
}
