<div fxLayout="column" style="width: 100%; margin-top: 20px">
    <div fxLayout="row" fxLayoutAlign="center center center" class="mat-title main-config">
      Ανακοινώσεις
    </div>
    <mat-divider style="width: 100%; padding-bottom: 50px;"></mat-divider>

    <div *ngIf="!loaded"  fxLayout="row" fxLayoutAlign="center center center">
        <div fxLayoutAlign="center center center" style="width: 100%;height: 60vh">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div *ngIf="loaded">
        <div style="width: 95%" class="options">
            <mat-form-field style="width:20%;">
                <mat-label>Αναζήτηση</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)">
            </mat-form-field>
            <div style="margin-right: 10px;">
              <button class="add-btn custom-btn" mat-raised-button (click)="create()">Προσθήκη Ανακοίνωσης</button>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center center">
        <div style="width: 95%" class="mat-elevation-z8" [ngClass]="{'hide': !loaded}">
            <table mat-table [dataSource]="dataSource" matSort style="width: 100%">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header >Α/Α</th>
                <td mat-cell *matCellDef="let tr"> {{tr.id}}</td>
              </ng-container>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Τίτλος</th>
                <td mat-cell *matCellDef="let tr"> {{ tr.title }} </td>
              </ng-container>
              <ng-container matColumnDef="created_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Ημερομηνία Δημιουργίας</th>
                <td mat-cell *matCellDef="let tr"> {{ tr.created_at | date: 'dd-MM-yyyy' }} </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  <div style="text-align: center; width: 80px;">
                    Ενέργεια
                  </div>
                </th>
                <td mat-cell *matCellDef="let tr">
                  <div style="text-align: center; width: 80px;">
                    <mat-icon matTooltip="Επεξεργασία" style="cursor: pointer;" (click)="edit(tr)">edit</mat-icon>
                    <mat-icon matTooltip="Διαγραφή" style="cursor: pointer; margin-left: 15px" (click)="deleteAnnouncement(tr)">delete</mat-icon>
                  </div>
                </td>
              </ng-container>
              <ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="6">Δεν βρέθηκαν αποτελέσματα..</td>
                </tr>
              </ng-container>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 15, 20]" [length]="dataSource.data.length" showFirstLastButtons></mat-paginator>
        </div>
    </div>


</div>
