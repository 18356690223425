import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {MainComponent} from './components/main/main.component';
import {UsersComponent} from './components/users/users.component';
import {UsersPrintComponent} from './components/users-print/users-print.component';
import {EditCategoryComponent} from './components/edit-category/edit-category.component';
import {ProfileComponent} from './components/profile/profile.component';
import { AccessGuard } from './access.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    component: LoginComponent,
    path: 'login',
  },
  {
    component: ForgotPasswordComponent,
    path: 'forgot-password',
  },
  {
    component: NavBarComponent,
    path: 'home',
    children: [
      {
        component: MainComponent,
        path: 'main',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: UsersComponent,
        path: 'users',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: ProfileComponent,
        path: 'profile',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: UsersPrintComponent,
        path: 'usersPrint',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: EditCategoryComponent,
        path: 'edit-category',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: DashboardComponent,
        path: 'dashboard',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: SettingsComponent,
        path: 'settings',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
      {
        component: AnnouncementsComponent,
        path: 'announcements',
        data: { requiresLogin: true },
        canActivate: [AccessGuard],
      },
    ],

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
