<h1 mat-dialog-title>
  <div class="header">
    <span style="font-size: larger;"> Προσθήκη Επιλογής</span>
    <mat-icon [mat-dialog-close]="false" style="cursor: pointer;">close</mat-icon>
  </div>
</h1>
<div fxLayout="column">
  <!-- <form [formGroup]="form">
    <div fxLayout="column" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label>Ονομασία</mat-label>
        <input formControlName="name" [maxLength]="30" matInput [(ngModel)]="name"/>
      </mat-form-field>
      <mat-form-field hintLabel="Μόνο αριθμοί">
        <mat-label>Τιμή</mat-label>
        <input (keydown)="checkInput($event)" formControlName="price" matInput [(ngModel)]="price"/>
      </mat-form-field>
    </div>
  </form>
  <mat-slide-toggle color="primary" [(ngModel)]="isChecked" (change)="getValue()">{{isChecked ? 'Ενεργή' : 'Ανενεργή'}}</mat-slide-toggle>
  <mat-slide-toggle color="primary" [(ngModel)]="hasScales" (change)="changeScale()">{{hasScales ? 'Εύρος τιμών' : 'Ανενεργό'}}</mat-slide-toggle> -->

  <form [formGroup]="form" *ngIf="success? closeDialog():true">
    <div fxLayout="column" fxLayoutGap="10px">
      <mat-form-field>
        <mat-label>Ονομασία</mat-label>
        <input formControlName="description" [maxLength]="20" matInput />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Τιμή</mat-label>
        <input type="number" formControlName="price" matInput/>
      </mat-form-field>
      <mat-slide-toggle color="primary" formControlName='is_active'>{{form.value.is_active ? "Ενεργή" :"Ανενεργή"}}</mat-slide-toggle>
      <mat-slide-toggle (change)="deleteAll()" color="primary" formControlName='has_scale'>{{form.value.has_scale ? "Με κλιμάκωση" :"Χωρίς κλιμάκωση"}}</mat-slide-toggle>
      <button type="button" mat-raised-button [disabled]="form.invalid" (click)="addScale()" (click)="increment()" style="width: 30%" *ngIf="form.value.has_scale">Προσθήκη Κλίμακας</button>
      <div formArrayName="scales">
        <div *ngFor="let scale of scaleFields.controls; let i=index" [formGroupName]="i">
          <mat-form-field [style.width.px]=80 (change)="scaleCheck(i)" style="margin-right: 50px"><mat-label>Από</mat-label>
            <input matInput type="number" [readonly]="!deletable[i]" formControlName="from"><mat-error *ngIf="!validScale">Παρακαλώ εισάγετε μια έγκυρη τιμή</mat-error>
          </mat-form-field>
          <mat-form-field [style.width.px]=80 (change)="scaleCheck(i)" style="margin-right: 50px"><mat-label>Έως</mat-label>
            <input matInput type="number"[readonly]="!deletable[i]" formControlName="to"><mat-error *ngIf="!validScale">Παρακαλώ εισάγετε μια έγκυρη τιμή</mat-error>
          </mat-form-field>
          <mat-form-field [style.width.px]=80 style="margin-right: 50px"><mat-label>Τιμή</mat-label>
            <input matInput type="number" formControlName="price">
          </mat-form-field>
          <button *ngIf="i===maxIndex" mat-raised-button (click)="deleteScale(i)">Αφαίρεση</button>
        </div>
      </div>


    </div>
<!--    <button mat-raised-button [disabled]="form.invalid">Αποθήκευση</button>-->
    <div mat-dialog-actions align="end">
      <button [disabled]="form.invalid" (click)="addProperty()" class="custom-btn" mat-raised-button>Αποθήκευση</button>
    </div>
  </form>

</div>
