import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';
import { AnnouncementDialogComponent } from '../announcement-dialog/announcement-dialog.component';
import { ConDialogComponent } from '../con-dialog/con-dialog.component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

  loaded = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  search: string;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ['id', 'title', 'created_at', 'actions'];
  announcements = [];
  announcementsTable = [];

  constructor(private dialog: MatDialog,
              public toastMessagesService: ToastMessagesService,
              private commonService: CommonService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService) { }

  async ngOnInit() {
    try {
      await this.initCalls();
    } catch (e) {
      // console.log(e);
    }
  }

  async initCalls() {
    try {

      this.loaded = false;

      this.announcements = await this.commonService.getRequest('announcement');

      this.announcementsTable = [];

      if (this.announcements && this.announcements.length > 0) {
        for (const tr of this.announcements) {

          const model = {
            id: tr.id,
            title: tr.title,
            created_at: tr.created_at,
            context: tr.context,
          };

          this.announcementsTable.push(model);
        }
      }

      this.loaded = true;

      this.dataSource.data = this.announcementsTable;

      this.dataSource.paginator = this.paginator;

      this.dataSource.sort = this.sort;

    } catch (e) {
      // console.log(e);
    }
  }

  async create() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      title: '',
      context: '',
      is_new: true
    };
    const dialogRef = this.dialog.open(AnnouncementDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.initCalls();
      }
    });
  }

  async edit(announcement) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      title: announcement.title,
      context: announcement.context,
      id: announcement.id,
      is_new: false,
    };

    const dialogRef = this.dialog.open(AnnouncementDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.initCalls();
      }
    });
  }

  async deleteAnnouncement(announcement) {
    try {

      const dialog = this.dialog.open(ConDialogComponent, {maxWidth: '400px',
        data: {
          title: 'Διαγραφή Ανακοίνωσης',
          message: `Είστε σίγουρος ότι θέλετε να διαγράψετε την ανακοίνωση;`,
        }
      });

      dialog.afterClosed().subscribe(async result => {
        if (result) {
          this.progressSpinnerDialogService.show();
          await this.commonService.deleteRequest(`announcement/${announcement.id}`);
          this.initCalls();
          this.progressSpinnerDialogService.end();
        }
      });

    } catch (error) {

    } finally {
    }
  }

  applyFilter(value: any) {
    this.dataSource.filter = value.trim().toLowerCase();
  }
}
