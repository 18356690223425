import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-category-dialog',
  templateUrl: './new-category-dialog.component.html',
  styleUrls: ['./new-category-dialog.component.scss']
})
export class NewCategoryDialogComponent implements OnInit {

  newCategory = '';
  newDetails = '';
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<NewCategoryDialogComponent>,
              public formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      newCategory: [
        '',
        Validators.compose([
          Validators.required
        ]),
      ],
      newDetails: [
        '',
        Validators.compose([
          Validators.required
        ]),
      ],
    });
  }

  submit() {
    if (this.form.valid) {
      this.closeDialog({ newCategory: this.form.get('newCategory')?.value, newDetails: this.form.get('newDetails')?.value });
    } else {
      this.closeDialog();
    }
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }

  isValid() {
    return this.newCategory && this.newDetails;
  }
}
