<div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 100vh">
    <mat-spinner></mat-spinner>
  </div>
  <div fxHide.lt-md style="width: 100%;height: 90vh" *ngIf="dataLoaded">
    <mat-drawer-container>
      <mat-drawer mode="side" opened [ngStyle.lt-md]="{'width':'200px'}" class="mat-elevation-z2">
        <mat-selection-list [multiple]="false">
          <div class="profile-card">
            <span class="title mat-title">Ρυθμίσεις</span>
          </div>
          <mat-divider></mat-divider>
          <mat-list-option aria-selected="true" selected disableRipple (click)="showDetails(1)">
            <mat-icon matListIcon>print</mat-icon>Επιλογές εκτύπωσης
            </mat-list-option>
            <mat-list-option aria-selected="true" disableRipple (click)="showDetails(2)">
                <mat-icon matListIcon>local_shipping</mat-icon>Τρόποι αποστολής
            </mat-list-option>
            <mat-list-option aria-selected="true" disableRipple (click)="showDetails(3)">
                <mat-icon matListIcon>receipt</mat-icon>Τρόποι πληρωμής
            </mat-list-option>
            <mat-list-option aria-selected="true" disableRipple (click)="showDetails(4)">
              <mat-icon matListIcon>call</mat-icon>Στοιχεία επικοινωνίας
            </mat-list-option>
            <mat-list-option aria-selected="true" disableRipple (click)="showDetails(5)">
              <mat-icon matListIcon>file_copy</mat-icon>Όροι χρήσης
            </mat-list-option>
          <mat-divider></mat-divider>
        </mat-selection-list>
      </mat-drawer>
      <mat-drawer-content>
        <div class="profile-content mat-elevation-z2">
          <app-settings-details [mode]="mode"></app-settings-details>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
  
  <div fxHide.gt-sm>

    <mat-tab-group (selectedTabChange)="changeTab($event)">
      <mat-tab label="Επιλογές Εκτύπωσης">  </mat-tab>
      <mat-tab label="Τρόποι Αποστολής"> </mat-tab>
      <mat-tab label="Τρόποι Πληρωμής">  </mat-tab>
      <mat-tab label="Στοιχεία Επικοινωνίας">  </mat-tab>
      <mat-tab label="Όροι Χρήσης">  </mat-tab>
    </mat-tab-group>

    <!-- <mat-toolbar color="primary" style="color: white;">
      <mat-toolbar-row fxLayoutAlign="space-between center">
        <button mat-button (click)="showDetails(1)">Επιλογές εκτύπωσης</button>
        <button mat-button (click)="showDetails(2)">Τρόποι αποστολής</button>
        <button mat-button (click)="showDetails(3)">Τρόποι πληρωμής</button>
      </mat-toolbar-row>
    </mat-toolbar> -->
    <div style="height: 100%; margin:2%;">
        <app-settings-details [mode]="mode"></app-settings-details>
    </div>
  </div>
  