import { Injectable } from '@angular/core';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EditService {

  constructor(private commonService: CommonService) { }

  async getCategories() {
    try {
      const categories = await this.commonService.getRequest(`category`);
      return categories;
    } catch (e) {
      throw e;
    }
  }

  async getProperties() {
    try {
      const properties = await this.commonService.getRequest(`property`);
      return properties;
    } catch (e) {
      throw e;
    }
  }

  async addNewCategory(item) {
    try {
      await this.commonService.postRequest(`category`, item);
    } catch (e) {
      throw e;
    }
  }
  async addNewProperty(item) {
    try {
      await this.commonService.postRequest(`property`, item);
    } catch (e) {
      throw e;
    }
  }

  async editProperty(item) {
    try {
     await this.commonService.putRequest(`property`, item);
    } catch (e) {
      throw e;
    }
  }

  async editCategory(item) {
    try {
      await this.commonService.putRequest(`category`, item);
    } catch (e) {
      throw e;
    }
  }

  async deleteCategory(id) {
    try {
      await this.commonService.deleteRequest(`category/${id}`);
    } catch (e) {
      throw e;
    }
  }

  async deleteProperty(id) {
    try {
      await this.commonService.deleteRequest(`property/${id}`);
    } catch (e) {
      throw e;
    }
  }
}
