
<div fxLayout="row" class="mat-title" style="margin: 20px" fxLayoutAlign="center center center">Επεξεργασία τρόπων αποστολής</div>
<mat-divider></mat-divider>
<div fxLayout="row">
    <div fxLayout="column">
        <button mat-raised-button class="add-btn custom-btn" (click)="newShippingModal()">
        Προσθήκη τρόπου αποστολής
        </button>
    </div>
</div>

  <div fxLayout="column" [ngStyle.lt-sm]="{'height': '70%', 'width':'90%'}">
    <div fxLayout="row wrap" fxLayout.lt-sm="column"  fxLayoutAlign="start center center">
      <mat-card class="mat-elevation-z5 card-config" [ngStyle.lt-md]="{'width':'80%'}" *ngFor="let item of shippings; let index = index;">
        <div fxLayoutAlign="space-between center" *ngIf="!isEdit(index)" style="margin: 5px">
          <div class="description">
            <span style="font-weight: bold">{{item.description}}</span>
            <mat-icon matTooltip="Ενεργή" style="cursor: initial;" *ngIf="item.is_active">visibility</mat-icon>
            <mat-icon matTooltip="Ανενεργή" style="cursor: initial;" *ngIf="!item.is_active">visibility_off</mat-icon>
          </div>
          <button *ngIf="canDelete(item)" mat-icon-button (click)="deleteShipping(item)" matTooltip="Διαγραφή τρόπου αποστολής">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div fxLayoutAlign="space-between center" *ngIf="!isEdit(index)" style="margin: 5px">
            <div><span>Τιμή: <span style="font-weight: bold">{{item.price}}</span></span></div>
        </div>

        <!-- <div *ngIf="isEdit(index)">
          <mat-form-field fxLayoutAlign="center">
            <input matInput [(ngModel)]="item.description"/>
          </mat-form-field>
        </div> -->
        <div fxLayout="row" fxLayoutAlign="center center center" style="position: absolute;bottom: 0; margin: 10px">
          <!-- <div *ngIf="!item.edit"> -->
          <div>
          <button class="custom-btn" (click)="editShippingDialog(item)" mat-raised-button>Επεξεργασία</button></div>
          <!-- <div *ngIf="item.edit" fxLayout="row" fxLayoutGap="20px">
            <mat-icon matTooltip="Αποθήκευση" (click)="editShipping(item); item.edit = !item.edit">done</mat-icon>
            <mat-icon matTooltip="Ακύρωση" (click)="item.edit = !item.edit">clear</mat-icon>
          </div> -->
        </div>
      </mat-card>
    </div>
  </div>
  <!--<div *ngIf="!dataLoaded" fxLayoutAlign="center center center" style="width: 100%;height: 100%"><mat-spinner></mat-spinner></div>-->
