import {Component, Input, OnInit} from '@angular/core';
import {TransactionService} from '../../services/transaction.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-users-info',
  templateUrl: './users-info.component.html',
  styleUrls: ['./users-info.component.scss']
})
export class UsersInfoComponent implements OnInit {
  @Input() info;

  // totalPrints: any;
  dataLoaded: boolean;
  search: string;

  // info: any;

  constructor(private transactionService: TransactionService, private router: Router
    // public dialogRef: MatDialogRef<UsersInfoComponent>,
    // @Inject(MAT_DIALOG_DATA) data
    ){
    // this.info = data;
    this.dataLoaded = false;
  }

  async ngOnInit() {

    try {
      this.dataLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  getUserPrintOrders(id) {
    this.router.navigate(['/home/usersPrint'], { queryParams: { id } });
  }
  // closeDialog() {
  //   this.dialogRef.close();
  // }

}
