import { ToastMessagesService } from './../../services/toast-messages.service';
import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-terms',
  templateUrl: './edit-terms.component.html',
  styleUrls: ['./edit-terms.component.scss']
})
export class EditTermsComponent implements OnInit {

  terms;
  dataLoaded = false;

  editorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',

    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  constructor(private commonService: CommonService, private toastMessagesService: ToastMessagesService) { }

  async ngOnInit() {
    try {
      const response = await this.commonService.getRequest('settings/terms');
      this.terms = response.context;
      this.dataLoaded = true;
    } catch (error) {
      console.log(error);
    }
  }

  async submit() {
    try {
      const response = await this.commonService.putRequest('settings/terms', {context: this.terms});
      this.terms = response.context;
      this.toastMessagesService.toastSuccessMessage('Οι όροι χρήσης αποθηκεύτηκαν επιτυχώς.');
    } catch (error) {
      console.log(error);
    }
  }
}
