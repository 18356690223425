import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../components/progress-spinner-dialog/progress-spinner-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ProgressSpinnerDialogService {

  dialogRef;
  constructor(private dialog: MatDialog) { }

  show() {
    this.dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
  }

  end() {
    if (this.dialogRef !== undefined) {
      this.dialogRef.close();
    }
  }
}
