import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {

  constructor(private toastMessagesService: ToastMessagesService,
              private commonService: CommonService,
              public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
              public formBuilder: FormBuilder) { }

  email = '';
  form: FormGroup;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.email,
          Validators.required
        ]),
      ],
    });
  }

  validEmail() {
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.email.match(regexEmail)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Description: sends put request with the submitted email value in order to
   * retrieve the login data
   */
  async putEmail() {
    if (!this.validEmail()) {
      this.toastMessagesService.toastErrorMessage('Παρακαλώ προσθέστε ένα έγκυρο email.');
    } else {

      const body = {
        email: this.email,
        admin_request: 1
      };

      try {
        await this.commonService.putRequest(`user/forgot`, body);
        this.toastMessagesService.toastSuccessMessage('Σας στάλθηκε email με οδηγίες για την ανάκτηση του κωδικού πρόσβασης.');
        this.dialogRef.close();
      } catch (error) {
        this.toastMessagesService.toastErrorMessage(error.error.message);
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
