import {Injectable} from '@angular/core';
import {CommonService} from './common.service';
import {Users} from '../interfaces/users';
import {TransactionService} from './transaction.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  // users: Users[] = [{
  //   id: 1,
  //   username: 'Foteini',
  //   password: 'user123',
  //   role: 'user',
  //   profile: {
  //     id: 1,
  //     address: 'Νέα Παραλία',
  //     phone: '2344556',
  //     aem: '123',
  //     displayName: 'Foteini P',
  //     postalCode: 23333,
  //     city: 'Θεσσαλονίκη'
  //   }
  // }, {
  //   id: 2,
  //   username: 'user',
  //   password: 'user1',
  //   role: 'user',
  //   profile: {
  //     id: 2,
  //     address: 'Μπότσαρη',
  //     phone: '2344566',
  //     aem: '193',
  //     displayName: 'User',
  //     postalCode: 23833,
  //     city: 'Θεσσαλονίκη'
  //   }
  // }
  // ];

  constructor(private commonService: CommonService, private transactionService: TransactionService) {
  }

  async getUsers() {
    try {
      const users = await this.commonService.getRequest(`user`);
      return users;
    } catch (e) {
      throw e;
    }
  }

  async getUserPrints(userId: number) {
    try {
      const totalPrints = await this.transactionService.getUserTransactions(userId);
      // const prints = totalPrints.filter(print => print.user_id === userId);
      return totalPrints;
    } catch (e) {
      throw e;
    }
  }

  async searchUser(item) {
    try {
      const searchItem = await this.commonService.getRequest(`searchUser?user=${item}`);
      return searchItem;
    } catch (e) {
      throw e;
    }
  }

  async changeRole(id) {
    try {
      const changedRole = await this.commonService.putRequest(`user/admin`, id);
    } catch (e) {
      throw e;
    }
  }

}
