
<h1 mat-dialog-title>
    <div class="header">
      <span style="margin-right: 50px;;"> Ανάκτηση κωδικού πρόσβασης</span>
      <mat-icon [mat-dialog-close]="false" style="cursor: pointer;">close</mat-icon>
    </div>
</h1>
<div mat-dialog-content>
    <form [formGroup]="form" style="width: 100%">
        <div class="container">
            <div class="fw">
                <div class="mat-subheading-1" fxLayoutAlign="start">
                <mat-icon class="icon-config info-item icon-color" style="margin-right: 5px;">info</mat-icon>Συμπληρώστε τη διεύθυνση email σας. 
                </div>
            </div>
            <div class="fw" style="margin-top: 15px;">
                <mat-form-field  class="fw">
                    <mat-label>Διεύθυνση email</mat-label>
                    <input matInput  formControlName="email" [(ngModel)]="email"/>
                    <mat-error *ngIf="form.get('email')?.value && form.get('email')?.invalid">Εισάγετε ένα έγκυρο email.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>

</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button class="custom-btn" [disabled]="!validEmail()" (click)="putEmail()">Επιβεβαίωση</button>
</div>
