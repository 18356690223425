<h1 mat-dialog-title>
  <div class="header">
      <span style="font-size: larger; margin-right: 20px;">{{getTitle()}}</span>
      <mat-icon  (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
  </div>
</h1>

<div fxLayout="column">
    <div fxLayout="column" fxLayoutGap="10px">
      <form [formGroup]="form" style="display: contents">
        <mat-form-field>
          <mat-label>Ονομασία</mat-label>
          <input formControlName="description" [maxlength]="45" matInput>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Τιμή</mat-label>
          <input formControlName="price" type="number" matInput>
        </mat-form-field>
        <mat-label>{{form.value.is_active ? 'Ενεργός' : 'Ανενεργός'}}</mat-label>
        <mat-slide-toggle formControlName="is_active"></mat-slide-toggle>

        <mat-label>{{form.value.has_shipping ? 'Εχει τρόπο αποστολής' : 'Δεν εχει τρόπο αποστολής'}}</mat-label>
        <mat-slide-toggle formControlName="has_shipping"></mat-slide-toggle>
      </form>

        <mat-form-field *ngIf="form.value.has_shipping">
          <mat-label>Τρόποι αποστολής</mat-label>
          <mat-select  [formControl]="shippings" multiple [compareWith]="compareFn">
            <mat-option *ngFor="let shipping of data.availableShippings" [value]="shipping.id">
              {{shipping.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
  </div>

<div mat-dialog-actions align="end">
  <button (click)="submit()" [disabled]="form.invalid || shippings.invalid" mat-raised-button class="custom-btn">Αποθήκευση αλλαγών</button>
</div>
