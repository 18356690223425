<div fxLayout="row" class="mat-title" style="margin: 20px" fxLayoutAlign="center center center">Όροι χρήσης</div>
<mat-divider style="padding-bottom: 20px"></mat-divider>

<div class="center" style="padding-bottom: 20px">
    <div fxLayoutAlign="start">
      <mat-icon class="icon-config info-color" style="margin-right: 5px;">info</mat-icon>
      <span style="margin-top: 3px;">
        Εδώ μπορείτε να δείτε και να επεξεργαστείτε τους όρους χρήσης της εφαρμογής. 
      </span>
    </div>
</div>

<div *ngIf="dataLoaded">
    <angular-editor [(ngModel)]="terms" [config]="editorConfig" placeholder="Περιεχόμενο"></angular-editor>
    
    <div class="center" style="margin-top:1%;" fxLayoutGap="40px">
        <button [disabled]="!terms" style="width: 220px" class="action-btn custom-btn" mat-raised-button (click)="submit()">Αποθήκευση</button>
    </div>
</div>
