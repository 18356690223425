<h1 mat-dialog-title>
  <div class="header">
    <span style="margin-right: 50px;;"> Λεπτομέρειες </span>
    <mat-icon [mat-dialog-close]="false" style="cursor: pointer;">close</mat-icon>
  </div>
</h1>

<div class="content" fxLayout="column" fxLayoutGap="2px">
  <div *ngIf="info.notes">
    <h4><strong>Σχόλια</strong></h4>
    <p>
      {{info.notes}}
    </p>
  </div>
  <div>
    <table>
      <tr>
        <th align="left">
          Στοιχεία Παραλήπτη
        </th>
      </tr>
      <tr>
        <td align="left">
          Διεύθυνση: 
        </td>
        <td>
          {{address !== null ? address.address : info.address}}
        </td>
      </tr>
      <tr>
        <td align="left">
          ΤΚ: 
        </td>
        <td>
          {{address !== null ? address.postal_code : info.postal_code}}
        </td>
      </tr>
      <tr>
        <td align="left">
          Τηλέφωνο:
        </td>
        <td>
          {{address !== null ? address.phone_number : info.phone_number}}
        </td>
      </tr>
    </table>
  </div>
  <div class="item-info">

    <table fxHide.lt-md style="margin-top: 20px; width: 100%">
      <tr>
        <th *ngFor="let opt of displayColumns">{{opt}}</th>
      </tr>
      <tr class="row" *ngFor="let item of info.options; let index = index">
        <ng-container *ngIf="item.file_id">
          <td style="text-align: center;"> {{index + 1}}</td>
          <td style="text-align: center;"> {{item.file_name}}</td>
          <td style="text-align: center;"> {{item.file_pages}}</td>
          <td style="text-align: center;"> {{item.copies}}</td>
          <td style="text-align: center;"> {{item.page_orientation_description}}</td>
          <td style="text-align: center;"> {{item.page_side_description}}</td>
          <td style="text-align: center;" *ngFor="let property of item.properties"> {{property.description}}</td>
          <td style="text-align: center;"><mat-icon style="cursor: pointer;" matTooltip="Κατέβασμα αρχείου" (click)="downloadFile(item.file_id, item.file_name)">download</mat-icon></td>
        </ng-container>
      </tr>
    </table>

    <div fxHide.gt-sm *ngFor="let item of info.options">
      
      <table style="margin-top: 20px;" class="options-table" *ngIf="item.file_id">
        <tr>
          <th align="left">
            Αρχείο
          </th>
        </tr>
        <tr>
          <td align="left">
            {{item.file_name}} 
          </td>
        </tr>
        <tr>
          <th align="left">
            Επιλογές εκτύπωσης 
          </th>
        </tr>
        <tr>
          <td align="left">
            Αντίτυπα: 
          </td>
          <td>
            {{item.copies}}  
          </td>
        </tr>
        <tr>
          <td align="left">
            Προσανατολισμός σελίδας: 
          </td>
          <td>
            {{item.page_orientation_description}}  
          </td>
        </tr>
        <tr>
          <td align="left">
            Μορφοποίηση σελίδας: 
          </td>
          <td>
            {{item.page_side_description}}
          </td>
        </tr>
        <tr *ngFor="let option of item.properties">
          <td align="left">
            {{option.category_description}}:
          </td>
          <td>
            {{option.description}}
          </td>
        </tr>
      </table>

      <div *ngIf="item.file_id" style="margin-top: 20px; margin-bottom: 10px; text-align: center;">
        <button mat-raised-button color="primary" (click)="downloadFile(item.file_id, item.file_name)"><mat-icon style="margin-right: 5px;">download</mat-icon>Κατέβασμα Αρχείου</button>
      </div>
    </div>
  </div>
</div>
