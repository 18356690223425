<h1 mat-dialog-title>
    <div class="header">
      <span style="margin-right: 50px;;"> Στοιχεία Τιμολόγησης</span>
      <mat-icon [mat-dialog-close]="false" style="cursor: pointer;">close</mat-icon>
    </div>
  </h1>

<div fxLayout="column" fxLayoutGap="2px" style="width: 400px;">
    
    <ng-container *ngIf="data !== undefined">
        <div fxLayout="row" fxLayoutAlign="start">
            <div>
                Τύπος Παραστατικού: <strong>{{data.is_invoice_description}}</strong>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start">
            <table *ngIf="data.is_invoice" style="min-width: 50%; margin-top: 5%;">
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Όνομα Εταιρείας:
                    </th>
                    <td>
                        {{data.company_name}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Επάγγελμα:
                    </th>
                    <td>
                        {{data.occupation}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Διεύθυνση 1:
                    </th>
                    <td>
                        {{data.address1}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Διεύθυνση 2:
                    </th>
                    <td>
                        {{data.address2}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Πόλη:
                    </th>
                    <td>
                        {{data.city}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Περιοχή:
                    </th>
                    <td>
                        {{data.area}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Τ.Κ:
                    </th>
                    <td>
                        {{data.postal_code}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Χώρα:
                    </th>
                    <td>
                        {{data.country}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Email:
                    </th>
                    <td>
                        {{data.invoice_email}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Α.Φ.Μ:
                    </th>
                    <td>
                        {{data.vat_number}}
                    </td>
                </tr>
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Δ.Ο.Υ.:
                    </th>
                    <td>
                        {{data.tax_office}}
                    </td>
                </tr>
            </table>
            <!-- <table *ngIf="!data.is_invoice" style="min-width: 40%; margin-top: 5%;">
                <tr style="width: 100%;">
                    <th style="text-align: start;">
                        Όνομα:
                    </th>
                    <td>
                        {{data.first_name}}
                    </td>
                </tr>
                <tr>
                    <th style="text-align: start;">
                        Επώνυμο:
                    </th>
                    <td>
                        {{data.last_name}}
                    </td>
                </tr>
                <tr>
                    <th style="text-align: start;">
                        Email:
                    </th>
                    <td>
                        {{data.email}}
                    </td>
                </tr>
            </table> -->
        </div>
    </ng-container>
    <ng-container *ngIf="data === undefined">
        <div fxLayout="row" fxLayoutAlign="start">
            <strong>Δεν βρέθηκαν στοιχεία τιμολόγησης</strong>
        </div>
    </ng-container>
  
</div>