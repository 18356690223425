import { AdminService } from './services/admin.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessGuard implements CanActivate {

  constructor(private adminService: AdminService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot)  {

    const requiresLogin = route.data.requiresLogin || false;

    if (requiresLogin) {

      try {
        const user = await this.adminService.getMyInfo();
        
        if (user) {
          return true;
        } else {
          this.router.navigateByUrl('/');
        }

      } catch (error) {
        this.router.navigateByUrl('/');
        
      }
    }
    return true;

  }
  
}
