<mat-card fxLayoutGap>

  <p mat-dialog-title>{{data.title}}</p>

  <p display-2>{{data.message}}</p>


  <div fxLayout="row" fxLayoutAlign="space-between center" mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Όχι</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Ναι</button>
  </div>
</mat-card>
