import {MatPaginatorIntl} from '@angular/material/paginator';

export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Στοιχεία ανά σελίδα:';
  customPaginatorIntl.lastPageLabel = 'Τελευταία σελίδα';
  customPaginatorIntl.firstPageLabel = 'Πρώτη σελίδα';
  customPaginatorIntl.nextPageLabel = 'Επόμενη σελίδα';
  customPaginatorIntl.previousPageLabel = 'Προηγούμενη σελίδα';
  customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 από ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} από ${length}`;
  };

  return customPaginatorIntl;
}
